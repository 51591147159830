import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class QuestionDescription extends React.Component {
    render() {
        const {taskName, description} = this.props;
        return (
            <div className={'row'}>
                <div
                    className="col-xl-12 col-sm-12">
                    <h3
                        className="text-dark hed">{taskName}</h3>
                    <h6 className="shrt-des">{description}</h6>

                </div>
            </div>
        )

    }
}


export default QuestionDescription
