import React, {Component} from 'react'
import Request from './../../request'
import lodash from "lodash";
import Countdown from "react-countdown-now";

class PaymentResponse extends React.Component {
    constructor() {
        super();
        this.state = {
            testHistory: [],
            testArr: [],
            totalAmount: 0,
        }
    }


    componentDidMount() {
        /*const {match: {params}} = this.props;
        if(!params.id){
            window.location.href='/dashboard'
        }*/
    }


    render() {
        return (
            <div className="content-wrapper-white">
                <div className="content breakTimer">
                    <div className="col-md-12">
                        <img src="assets/img/payment-failed.jpg" className=" paymnt-img mt-4 mb-4"/>
                        <h2 class="paymentext mb-3">Payment Failed</h2>
                    </div>
                </div>

            </div>
        )
    }
}


export default PaymentResponse;


