import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
// We will create these two pages in a moment;


import Dashboard from './containers/dashboard';
import About from './containers/about';
import ViewQuestion from './containers/questions/view';
import TestSubmit from './containers/test/testSubmit';
import Login from './containers/auth/login';
import Signup from './containers/auth/signup';
import Forgot from './containers/auth/forgot';
import ResetPassword from './containers/auth/resetPassword';
import PurchaseTest from './containers/test/purchaseTest';
import TestHistory from './containers/test/testHistory';
import TransactionsList from './containers/test/transactionsList';
import UserProfile from './containers/user/userProfile';
import ChangePassword from './containers/user/changePassword';
import BreakTime from './containers/test/breakTime';
import LoginLayoutRoute from "./authLayout";
import InnerLayout from "./innerLayout";
import TestLayout from "./testLayout";
import PractiseTest from './containers/practiseTest';
import PaymentResponse from './containers/paymentPages/paymentResponse';
import PaymentSuccess from './containers/paymentPages/paymentSuccess';
import PaymentFail from './containers/paymentPages/paymentFail';


const isLoggedIn = localStorage.getItem('token');
const loginUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

export default function App() {
    navigator.mediaDevices.getUserMedia({audio: true})
        .then(function (stream) {
            // console.log("sdf", stream)
            localStorage.setItem('audio', true);
        })
        .catch(function (err) {


        });
    return (
        <div>
            {isLoggedIn ?
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/dashboard"/>
                        </Route>
                        <InnerLayout path="/dashboard" component={Dashboard}/>
                        {loginUser && loginUser.userType == 'Inhouse Student' &&
                        <InnerLayout path="/practiseTest" component={PractiseTest}/>}
                        <InnerLayout path="/about" component={About}/>
                        <InnerLayout path="/purchaseTest" component={PurchaseTest}/>
                        <TestLayout path="/viewQuestion/:testId" component={ViewQuestion}/>
                        <TestLayout path="/breakTime/:testId" component={BreakTime}/>
                        <InnerLayout path="/testSubmit/:testId" component={TestSubmit}/>
                        <InnerLayout path="/transactions" component={TransactionsList}/>
                        <InnerLayout path="/testHistory" component={TestHistory}/>
                        <InnerLayout path="/myProfile" component={UserProfile}/>
                        <InnerLayout path="/changePassword" component={ChangePassword}/>
                        <InnerLayout path="/paymentResponse" component={PaymentResponse}/>
                        <InnerLayout path="/paymentFail" component={PaymentFail}/>
                        <InnerLayout path="/paymentSuccess" component={PaymentSuccess}/>
                        <Redirect to="/"/>
                    </Switch>
                </Router>
                :
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/login"/>
                        </Route>
                        <LoginLayoutRoute path="/login" component={Login}/>
                        <LoginLayoutRoute path="/signup" component={Signup}/>
                        <LoginLayoutRoute path="/forgotPassword" component={Forgot}/>
                        <LoginLayoutRoute path="/resetPassword/:key" component={ResetPassword}/>
                        <Redirect to="/"/>
                    </Switch>
                </Router>
            }
        </div>

    )
}
