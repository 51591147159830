import React from 'react'
import Request from './../../request'
import {Redirect, Route} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';

class Login extends React.Component {
    createNotification = (type) => {
        return () => {
            switch (type) {
                case 'info':
                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success('Success message', 'Title here');
                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {
                        alert('callback');
                    });
                    break;
            }
        };
    };

    userLogin = async (event) => {
        event.preventDefault();
        let obj = {
            email: this.state.email,
            password: this.state.password
        }
        this.setState({isLoading: true})
        let resp = await Request.login(obj);
        if (resp.success) {
            localStorage.setItem('token', resp.token);
            localStorage.setItem('user', JSON.stringify(resp.user));
            window.location.href = "/";
            this.setState({isLoading: false})
        } else {
            NotificationManager.warning(resp.message);
            this.setState({isLoading: false})
        }
    }

    constructor() {
        super();
        this.state = {
            email: null,
            password: null,
            isLoading: false
        }
    }

    render() {
        const {isLoading} = this.state;
        return (
            <div className='bg-light-gray'>
                <div className="container d-flex flex-column justify-content-between vh-100">
                    <div className="row mt-3">
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <img src="assets/img/summit-logo.png" alt="Summit Language Learning"/>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10 text-right pt-1">
                            <button type="button" className="mt-2 btn btn-green"><i
                                className="mdi mdi-arrow-left"></i> Visit Website
                            </button>
                            {/*<button type="button" className="mb-1 btn btn-price">Test Pricing</button>*/}
                        </div>


                    </div>

                    <div className="row mt-2">
                        <div className="col-xl-6 col-lg-6 col-md-10 learn-frm">


                            <ul className="learn-lst mb-4">
                                {/*<li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>*/}
                            </ul>

                            <img src="assets/img/user/login.png" className="login-img mt-4"/>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <div className="card">
                                <div className="card-body login">
                                    <h3>Login your Account</h3>
                                    <h6>to continue with summit PTE Exam</h6>
                                    <hr/>
                                    <form onSubmit={this.userLogin}>
                                        <div className="row log-frm">
                                            <div className="form-group col-md-11 mb-4">
                                                <input type="email" className="form-control input-lg" id="email"
                                                       value={this.state.email}
                                                       required
                                                       onChange={(e) => this.setState({email: e.target.value})}
                                                       aria-describedby="emailHelp" placeholder="Username"/>
                                            </div>
                                            <div className="form-group col-md-11">
                                                <input type="password" className="form-control input-lg" id="password"
                                                       value={this.state.password}
                                                       required
                                                       onChange={(e) => this.setState({password: e.target.value})}
                                                       placeholder="Password"/>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex my-2 justify-content-between">
                                                    <div className="col-md-6">
                                                        <p className="frget-pass"><a href="/forgotPassword">Forgot
                                                            Password?</a></p>
                                                    </div>

                                                    <div className="col-md-6 sub-btn">
                                                        <button type="submit" disabled={isLoading}
                                                                className="btn btn-lg mb-4">{isLoading ? 'Please Wait...' : 'LOGIN'}</button>
                                                    </div>


                                                </div>

                                                <p style={{textAlign: 'center', marginTop: '2%'}}>If you don't have any
                                                    account yet, please signup with us</p>

                                            </div>
                                            <div className='col-md-12 align-center'>
                                                <button type="button" className="reg-btn">
                                                    <a href="/signup">I don't have any account yet <br/>
                                                        <span>REGISTER</span>
                                                    </a>
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="copyright pl-0">
                        <p className="text-center" style={{color: '#ffffffbf'}}>&copy; Copyright - Summit 2019 - PTE
                            Learning
                            Programme
                        </p>
                    </div>
                </div>
            </div>
        )
    }

}

export default Login;


