import React, {Component} from 'react'


class CutCopy extends React.Component {

    state = {
        val: '',

    };

    constructor(props) {
        super(props)
    }


    render() {
        return (
            <div className={'row col-md-12 txtbtn'}>
                <div className={'col-md-4'}>
                    <button onClick={() => {
                        document.execCommand('cut')
                    }} className={'cut'}>CUT
                    </button>
                </div>
                <div className={'col-md-4 text-center'} onClick={() => {
                    document.execCommand('copy')
                }}>
                    <button className={'cut'}>COPY</button>
                </div>
                <div className={'col-md-4 text-right'}>
                    <button className={'cut'} onClick={() => {
                        navigator.clipboard.readText()
                            .then(text => {
                                this.props.pasteTxt(text)
                            })
                            .catch(err => {

                            });
                    }}>PASTE
                    </button>
                </div>
            </div>
        )
    }
}

export default CutCopy
