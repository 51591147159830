import React, {Component} from 'react';

import _ from 'lodash';

class Essay extends React.Component {

    state = {
        val: null,
        fibOptions: []
    };

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.info && this.props.info.fibOptions) {
            this.setState({fibOptions: this.props.info.fibOptions})
        }

    }

    getQuestionAnswer = () => {


        this.props.moveNext()


        let arr = []
        _.each(this.state.fibOptions, (val) => {
            if (val.value) {
                arr.push({value: val.value, listId: val._id})
            }
        })

        this.props.sampleObj({fibOptions: arr})
    }

    tempAnswer = (options, value) => {


        let fibOptions = this.state.fibOptions;

        let findIn = _.find(fibOptions, (val) => {
            return val._id == options._id
        })
        if (findIn) {
            findIn.value = value;
        }

        this.setState({fibOptions})


    }


    render() {

        const {fibOptions} = this.state;
        const {info} = this.props;


        return (<div className={'row'}>

                <div className="mt-3 col-md-12 col-lg-12 col-xl-12 wrtn-que">
                    {info.textArray.map((val, index) => {
                        return (
                            <span key={index}>

                                {val}
                                {fibOptions[index] && fibOptions[index].options &&
                                <select className={'fibselect '}
                                        onChange={(qw) => this.tempAnswer(fibOptions[index], qw.target.value)}>
                                    <option key={'sd'} value={null}></option>
                                    {
                                        fibOptions[index] && fibOptions[index].options.map((vd, sd) => {
                                            return (<option key={sd} value={vd.value}>{vd.value}</option>)
                                        })
                                    }
                                </select>}

                            </span>
                        )
                    })}
                </div>


            </div>
        )
    }


}

export default Essay
