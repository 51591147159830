import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import QuestionDescription from './questionDescription'

class PreparationTimer extends React.Component {


    render() {
        const {preparationDuration} = this.props;
        return (


            <div className={'row'}>
                <div className={'col-md-4 '}></div>
                <div className={'col-md-4'}>
                    <div className={'alert alert-warning'}> Recording Beginning In {preparationDuration}
                    </div>
                </div>
                <div className={'col-md-4 '}></div>
            </div>
        )
    }
}

export default PreparationTimer
