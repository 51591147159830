import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import moment from 'moment'

import {apiUrl} from './../../settings'
import Request from './../../request'
import {NotificationManager} from 'react-notifications';
import SquareConnect from 'square-connect';

const defaultClient = SquareConnect.ApiClient.instance;

// Configure OAuth2 access token for authorization: oauth2
var oauth2 = defaultClient.authentications['oauth2'];
oauth2.accessToken = 'EAAAEJAfJyGfkcctmseQ4hbvUfDWleKtRHLqn1aZZFUuKQWDv1ag_8DjxuAJadfD';

const apiInstance = new SquareConnect.TransactionsApi();

const money = new SquareConnect.Money();
money.amount = 100;
money.currency = "USD";

const locationId = "PB7DD187ZCTCB";

const body = new SquareConnect.ChargeRequest(); // CreateCheckoutRequest | An object containing the fields to POST for the request.  See the corresponding object definition for field details.


class PurchaseTest extends React.Component {
    async getPublishTest() {
        let testArr = []
        let resp = await Request.getPublishTestForUser();
        lodash.each(resp.data, (item) => {
            item.selected = false;
            testArr.push(item);
        })
        this.setState({testArr})
    }

    selectTest = (data) => {
        let totalAmount = 0;
        let cloneData = lodash.clone(this.state.testArr);
        let findTest = lodash.find(cloneData, (item) => {
            return item._id == data._id;
        })
        if (findTest) {
            findTest.selected = !findTest.selected
        }
        lodash.each(cloneData, (item) => {
            if (item.selected) {
                totalAmount = totalAmount + item.price;
            }
        })
        this.setState({
            totalAmount: totalAmount,
            testArr: cloneData,
        })
    }

    constructor() {
        super();
        this.state = {
            testArr: [],
            totalAmount: 0,
            isLoading: false
        }
    }

    async purchaseTest() {
        this.setState({isLoading: true})
        let testIds = []
        lodash.each(this.state.testArr, (item) => {
            if (item.selected) {
                testIds.push(item._id);
            }
        })
        let obj = {
            "testIds": testIds,
            "mode": "Card",
            "msg": "testing",
            totalAmount: this.state.totalAmount
        }
        let resp = await Request.purchaseTest(obj);
        if (resp.success) {
            NotificationManager.success('Test Purchased successfully.');
            this.getPublishTest();
            this.setState({totalAmount: 0, isLoading: false})
        } else {
            NotificationManager.error(resp.msg, 'Error');
        }
    }

    async checkout() {
        this.setState({isLoading: true})
        let testIds = []
        lodash.each(this.state.testArr, (item) => {
            if (item.selected) {
                testIds.push(item._id);
            }
        })
        let obj = {
            "testIds": testIds,
            "mode": "Card",
            "msg": "testing",
            totalAmount: this.state.totalAmount
        }
        let resp = await Request.checkout(obj);
        if (resp.success) {
            window.location.href = resp.redirectUrl;
            this.setState({totalAmount: 0, isLoading: false})
        } else {
            NotificationManager.error(resp.msg, 'Error');
        }
    }


    componentDidMount() {
        this.getPublishTest();
        this.getAllEmployeeRoles()
    }

    getAllEmployeeRoles() {

        body.idempotency_key = "YTHDGJIK";
        body.amount_money = money;
        body.cardNonce = "CBASECXnYKA9fd8KXid_9sKAHukgAF";
        body["referenceId"] = "Confirmation purchase";
        body["note"] = "Thank you for your purchase";
        apiInstance.charge(locationId, body).then(function (data) {
            console.log('API called successfully. Returned data: ' + data);
        }, function (error) {
            console.log(error);
        });
    }

    render() {
        const {testArr, totalAmount, isLoading} = this.state;
        return (
            <div className="container mt-1 pt-2">
                <div className={'row'}>
                    <div className={'col-md-12 text-center'}>
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom card-header-new">
                                <h2>Purchase Test</h2>
                                <span>(Total : {testArr.length})</span>
                            </div>
                            <div className="card-body card-body1">
                                {testArr && testArr.length ?
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            {/*<th scope="col">Test Date</th>*/}
                                            <th scope="col">Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {testArr.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td scope="row">
                                                        <label className="control control-checkbox">
                                                            <input type='checkbox'
                                                                   onChange={() => this.selectTest(item)}
                                                                   checked={item.selected}
                                                                   value={item.selected}/>
                                                            <div className="control-indicator"></div>
                                                        </label>

                                                    </td>
                                                    <td>{item.testName}</td>
                                                    {/*  <td>{moment(item.testDate).format('DD MMMM YYYY')}</td>*/}
                                                    <td>${item.price}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        <tr>
                                            <td colSpan={2}></td>
                                            <td colSpan={2} className='align-right'>
                                                Total Amount :${totalAmount}
                                            </td>
                                        </tr>

                                        {totalAmount > 0 &&
                                        <tr>
                                            <td colSpan={2}></td>
                                            <td colSpan={2} className='align-right'>
                                                <button className="btn btn-success" disabled={isLoading}
                                                        onClick={lodash.debounce(() => {
                                                            this.checkout();
                                                        }, 1000)}>
                                                    {isLoading ? 'Please Wait...' : 'Purchase Now'}
                                                </button>
                                            </td>
                                        </tr>
                                        }
                                    </table> :
                                    <div className='align-center'>
                                        <h3>No Test to Purchase</h3>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <button className="btn btn-success" disabled={isLoading}
                                onClick={lodash.debounce(() => {
                                    this.checkout();
                                }, 1000)}>
                            {isLoading ? 'Please Wait...' : 'Checkout'}
                        </button>*/}
                    </div>
                </div>

            </div>
        )
    }
}


export default PurchaseTest;
