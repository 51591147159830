import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import moment from 'moment'
import Request from './../../request'
import {NotificationManager} from 'react-notifications';

class ChangePassword extends React.Component {
    updatePassword = async (event) => {
        event.preventDefault();
        let obj = this.state;
        if (obj.newPassword.length < 6) {
            NotificationManager.warning('Please enter min 6 digit password.');
            return
        }
        if (obj.newPassword !== obj.confirmPassword) {
            NotificationManager.warning('Confirm password not match.');
            return
        }
        let resp = await Request.changePasswordFxn(obj);
        if (resp.success) {
            NotificationManager.success(resp.msg);
            this.setState({
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            })
        } else {
            NotificationManager.error(resp.msg);
        }
    }

    constructor() {
        super();
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="container mt-1 pt-2">
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom card-header-new">
                                <h2>Change Password</h2>
                            </div>
                            <div className="card-body card-body1">
                                <form onSubmit={this.updatePassword} className={'row'}>
                                    <div className="form-row col-md-6">
                                        <div className={'row pb-4'}>
                                            <div className="col-md-12">
                                                <label>Current Password</label>
                                                <input type="password" className="form-control"
                                                       onChange={(e) => this.setState({currentPassword: e.target.value})}
                                                       placeholder="Current Password" value={this.state.currentPassword}
                                                       required/>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <label>New Password</label>
                                                <input type="password" className="form-control"
                                                       onChange={(e) => this.setState({newPassword: e.target.value})}
                                                       placeholder="New Password" value={this.state.newPassword}
                                                       required/>
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                <label>Confirm Password</label>
                                                <input type="password" className="form-control"
                                                       onChange={(e) => this.setState({confirmPassword: e.target.value})}
                                                       placeholder="Confirm Password" value={this.state.confirmPassword}
                                                       required/>
                                            </div>

                                            <div className="col-md-12 mt-3 recrd align-left">
                                                <button className="btn btn-primary" type="submit">Change Password
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default ChangePassword;
