import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import moment from 'moment'
import Request from './../../request'
import {NotificationManager} from 'react-notifications';
import styles from './styles.css'


class TestHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            resultArr: [],
            status: null,
            mode: null,
            amount: null
        }
    }

    async getPublishTest() {
        let resultArr = []
        let obj = {};
        if (this.state.status) {
            obj.status = this.state.status;
        }
        if (this.state.mode) {
            obj.mode = this.state.mode;
        }
        if (this.state.amount) {
            obj.amount = this.state.amount;
        }
        if (this.state.testName) {
            obj.testName = this.state.testName;
        }
        if (this.state.testDate) {
            obj.testDate = this.state.testDate;
        }
        if (this.state.paymentStatus) {
            obj.paymentStatus = this.state.paymentStatus;
        }
        let resp = await Request.studentTestHistory(obj);
        lodash.each(resp.data, (item) => {
            resultArr.push(item);
        })
        this.setState({resultArr})
    }

    searchByFilter() {
        this.getPublishTest();
    }

    componentDidMount() {
        this.getPublishTest();
    }

    async downloadResultPdf(data, index) {
        let cloneArr = lodash.clone(this.state.resultArr)
        cloneArr[index].isLoading = true;
        this.setState({resultArr: cloneArr})
        let resp = await Request.downloadResultPdf(data);
        if (resp) {
            cloneArr[index].isLoading = false;
            this.setState({resultArr: cloneArr});
            window.open(resp, 'Download')
        }
    }

    render() {
        const {resultArr} = this.state;
        return (
            <div className="container mt-1 pt-2">
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom card-header-new">
                                <h2>Test History</h2>
                                <span>(Total : {resultArr.length})</span>
                            </div>
                            <div className="card-body card-body1">
                                <div className='row'>
                                    <div className="form-group col-md-3">
                                        <label>Filter by Payment Status</label>
                                        <select className="form-control form-control-select input-xs"
                                                value={this.state.paymentStatus}
                                                onChange={(e) => this.setState({paymentStatus: e.target.value})}>
                                            <option value=''>Select Payment Status</option>
                                            <option value='Pending'>Pending</option>
                                            <option value='Paid'>Paid</option>
                                            <option value='Canceled'>Canceled</option>

                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Filter by Name</label>
                                        <input type={'text'} className="form-control form-control-number"
                                               value={this.state.testName} placeholder={'Filter by Name'}
                                               onChange={(e) => this.setState({testName: e.target.value})}/>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label>Filter by Status</label>
                                        <select className="form-control form-control-select input-xs"
                                                value={this.state.status}
                                                onChange={(e) => this.setState({status: e.target.value})}>
                                            <option value=''>Select Status</option>
                                            <option value='Not Attempted'>Not Attempted</option>
                                            <option value='Attempted'>Attempted</option>
                                            <option value='Submitted'>Submitted</option>
                                            <option value='Checked'>Checked</option>
                                        </select>
                                    </div>
                                    {/* <div className="form-group col-md-3">
                                        <label>Filter by Mode</label>
                                        <select className="form-control form-control-select input-xs"
                                                value={this.state.mode}
                                                onChange={(e) => this.setState({mode: e.target.value})}>
                                            <option value=''>Select Mode</option>
                                            <option value='Cash'>Cash</option>
                                            <option value='Net Banking'>Net Banking</option>
                                            <option value='Draft'>Draft</option>
                                        </select>
                                    </div>*/}
                                    <div className="form-group col-md-2">
                                        <label>Filter by Amount</label>
                                        <input type={'number'} className="form-control form-control-number"
                                               value={this.state.amount} placeholder={'Filter by Amount'}
                                               onChange={(e) => this.setState({amount: e.target.value})}/>
                                    </div>

                                    {/* <div className="form-group col-md-3">
                                        <label>Filter by Test Date</label>
                                        <input type={'date'} className="form-control"
                                               value={this.state.testDate}
                                               onChange={(e) => this.setState({testDate: e.target.value})}/>
                                    </div>*/}
                                    <div className="form-group col-md-2 align-center recrd mt-4">
                                        <a className='btn btn-new'
                                           onClick={() => this.searchByFilter()}
                                        >Search</a>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        {resultArr && resultArr.length ?
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Test No</th>
                                                    <th scope="col">Test Name</th>
                                                    <th scope="col">Amount</th>
                                                    {/*
                                                    <th scope="col">Payment Status</th>
*/}
                                                    <th scope="col">Test Status</th>
                                                    <th scope="col">Test Date</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {resultArr.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td scope="row">
                                                                {index + 1}
                                                            </td>
                                                            <td scope="row">
                                                                {item.testNo}
                                                            </td>

                                                            <td>{item.testName}</td>
                                                            <td>{item.payment.paidAmount}</td>
                                                            {/*  <td>
                                                                {item.payment.status == 'Assigned' &&
                                                                <label
                                                                    className='label label-info'>{item.payment.status}</label>}

                                                                {item.payment.status == 'Pending' &&
                                                                <label
                                                                    className='label label-warning'>{item.payment.status}</label>}
                                                                {item.payment.status == 'Paid' &&
                                                                <label
                                                                    className='label label-success'>{item.payment.status}</label>}

                                                                {item.payment.status == 'Canceled' &&
                                                                <label
                                                                    className='label label-danger'>{item.payment.status}</label>}
                                                            </td>*/}
                                                            <td>
                                                                {item.testStatus == 'Not Attempted' &&
                                                                <label
                                                                    className='label label-info'>{item.testStatus}</label>}

                                                                {item.testStatus == 'Attempted' &&
                                                                <label
                                                                    className='label label-warning'>{item.testStatus}</label>}
                                                                {item.testStatus == 'Submitted' &&
                                                                <label
                                                                    className='label label-success'>{item.testStatus}</label>}

                                                                {item.testStatus == 'Checked' &&
                                                                <label
                                                                    className='label label-danger'>{item.testStatus}</label>}
                                                            </td>
                                                            <td>{moment(item.testDate).format('DD MMMM YYYY')}</td>
                                                            <td>
                                                                {item.testStatus == 'Checked' &&
                                                                <button className={'btn btn-new btn-info btn-sm'}
                                                                        disabled={item.isLoading}
                                                                        onClick={() => this.downloadResultPdf(item, index)}>
                                                                    {item.isLoading ? 'Downloading...' : 'Download Result'}
                                                                </button>
                                                                }

                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>

                                            </table> :
                                            <h1 className={'align-center mt-5 mb-5 ntrans'}>No History</h1>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default TestHistory;
