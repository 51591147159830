import React from 'react'
import Request from './../../request'
import {Redirect, Route} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';

class Login extends React.Component {
    forgotPassword = async (event) => {
        event.preventDefault();
        let obj = {
            email: this.state.email
        }
        let resp = await Request.forgotPasswordAjax(obj);
        if (resp.success) {
            NotificationManager.success(resp.msg);
            setTimeout(() => {
                window.location.href = "/login";
            }, 1000)
        } else {
            NotificationManager.error(resp.msg);
        }
    }

    constructor() {
        super();
        this.state = {
            email: null
        }
    }

    render() {
        return (
            <div className='bg-light-gray'>
                <div className="container d-flex flex-column justify-content-between vh-100">
                    <div className="row mt-4">
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <img src="assets/img/summit-logo.png" alt="Summit Language Learning"/>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10 text-right pt-2">
                            <button type="button" className="mb-1 btn btn-green"><i
                                className="mdi mdi-arrow-left"></i> Visit Website
                            </button>
                            {/*<button type="button" className="mb-1 btn btn-price">Test Pricing</button>*/}
                        </div>


                    </div>

                    <div className="row mt-3 mb-3">
                        <div className="col-xl-6 col-lg-6 col-md-10 learn-frm">


                            <ul className="learn-lst mb-4">
                                {/* <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>*/}
                            </ul>

                            <img src="assets/img/user/login.png" className="login-img"/>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <div className="card">
                                <div className="card-body login">
                                    <h3>Forgot Password</h3>
                                    <h6>to continue with summit PTE Exam</h6>
                                    <hr/>
                                    <form onSubmit={this.forgotPassword} className="mb-5">
                                        <div className="row log-frm">
                                            <div className="form-group col-md-11 mb-5 mt-4">
                                                <input type="email" className="form-control input-lg" id="email"
                                                       value={this.state.email}
                                                       required
                                                       onChange={(e) => this.setState({email: e.target.value})}
                                                       aria-describedby="emailHelp" placeholder="Enter Email"/>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex my-2 justify-content-between">
                                                    <div className="col-md-6 sub-btn">
                                                        <button type="submit" className="btn btn-lg mb-4">SUBMIT
                                                        </button>
                                                    </div>
                                                    <div className="col-md-6 align-center">
                                                        <p>Already have an account?<br/>

                                                            <a className="text-blue" href="/login"> Sign in</a>

                                                        </p>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="copyright pl-0">
                        <p className="text-center" style={{color: '#ffffffbf'}}>&copy; Copyright - Summit 2019 - PTE
                            Learning
                            Programme
                        </p>
                    </div>
                </div>
            </div>
        )
    }

}

export default Login;


