import React, {Component} from 'react'


class Beep extends React.Component {


    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }


    playBeep = () => {

        let x = document.getElementById('myAudio');
        x.play();
        // this.props.startRecordingNow()
    }


    render() {


        return (
            <audio id="myAudio">
                <source src="/audio/beep.mp3" type="audio/mpeg"/>
            </audio>)
    }
}

export default Beep
