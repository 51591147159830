import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import moment from 'moment'
import styles from './styles.css'
import Request from './../../request'
import {NotificationManager} from 'react-notifications';

class PurchaseTest extends React.Component {
    constructor() {
        super();
        this.state = {
            resultArr: [],
            status: null,
            mode: null,
            amount: null
        }
    }

    async getPublishTest() {
        let resultArr = []
        let obj = {};
        if (this.state.status) {
            obj.status = this.state.status;
        }
        if (this.state.mode) {
            obj.mode = this.state.mode;
        }
        if (this.state.amount) {
            obj.amount = this.state.amount;
        }
        let resp = await Request.studentTransactions(obj);
        lodash.each(resp.data, (item) => {
            resultArr.push(item);
        })
        this.setState({resultArr})
    }

    searchByFilter() {
        this.getPublishTest();
    }

    componentDidMount() {
        this.getPublishTest();
    }

    render() {
        const {resultArr} = this.state;
        return (
            <div className="container mt-1 pt-2">
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom card-header-new">
                                <h2>Transactions Report</h2>
                                <span>(Total : {resultArr.length})</span>
                            </div>
                            <div className="card-body card-body1">
                                <div className='row'>
                                    <div className="form-group col-md-3">
                                        <label>Filter by Status</label>
                                        <select className="form-control form-control-select input-xs"
                                                value={this.state.status}
                                                onChange={(e) => this.setState({status: e.target.value})}>
                                            <option value=''>Select Status</option>
                                            <option value='Pending'>Pending</option>
                                            <option value='Paid'>Paid</option>
                                            <option value='Canceled'>Canceled</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Filter by Mode</label>
                                        <select className="form-control form-control-select input-xs"
                                                value={this.state.mode}
                                                onChange={(e) => this.setState({mode: e.target.value})}>
                                            <option value=''>Select Mode</option>
                                            <option value='Cash'>Cash</option>
                                            <option value='Net Banking'>Net Banking</option>
                                            <option value='Draft'>Draft</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Filter by Amount</label>
                                        <input type={'number'} className="form-control form-control-number"
                                               value={this.state.amount} placeholder={'Filter by Amount'}
                                               onChange={(e) => this.setState({amount: e.target.value})}/>
                                    </div>
                                    <div className="form-group col-md-3 align-center recrd">
                                        <a className='btn btn-new btn-info btn-sm' style={{marginTop: 30}}
                                           onClick={() => this.searchByFilter()}
                                        >Search</a>
                                    </div>
                                </div>
                                <div className={'row pb-3'}>
                                    <div className={'col-md-12'}>
                                        {resultArr && resultArr.length ?
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Test Name</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Paid Amount</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Mode</th>
                                                    {/*<th scope="col">Message</th>*/}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {resultArr.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td scope="row">
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                <ul style={{marginLeft: 10}}>
                                                                    {item.testIds.map((item1, index) => {
                                                                        return (
                                                                            <li key={index}>{item1.testName}</li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </td>
                                                            <td>
                                                                {item.status == 'Assigned' &&
                                                                <label
                                                                    className='label label-info'>{item.status}</label>}

                                                                {item.status == 'Pending' &&
                                                                <label
                                                                    className='label label-warning'>{item.status}</label>}

                                                                {item.status == 'Paid' &&
                                                                <label
                                                                    className='label label-success'>{item.status}</label>}

                                                                {item.status == 'Canceled' &&
                                                                <label
                                                                    className='label label-danger'>{item.status}</label>}

                                                            </td>
                                                            <td>${item.totalAmount}</td>
                                                            <td>{moment(item.date).format('DD MMMM YYYY')}</td>
                                                            <td>{item.mode}</td>
                                                            {/*<td>{item.msg}</td>*/}
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>

                                            </table> :
                                            <h1 className={'align-center mt-5 mb-5 ntrans'}>No Transactions</h1>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default PurchaseTest;
