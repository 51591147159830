import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/dist/react-notifications.css'
import './App.css'
import Request from './request'
let currentPath = null;
const loginUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
const logoutFxn = async () => {

    await Request.logout()
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.reload()
}

const DashboardLayout = ({children, ...rest}) => {
    return (
        <div className="page page-dashboard">
            <aside className="left-sidebar bg-sidebar">
                <div className="sidebar sidebar-with-footer" id="sidebar">
                    <div className="app-brand">
                        <a href="/" className='logo'>
                            <img alt="Summit Language Learning" src="/assets/img/summit-logo.png" className='isLarge'/>
                            {/*<img alt="Summit Language Learning" src="/assets/img/summit-logo.png" className='isLarge'/>*/}
                        </a>
                    </div>

                    <div className="sidebar-scrollbar">

                        <ul className="nav sidebar-inner" id="sidebar-menu">
                            <li className={`has-sub ${currentPath == '/dashboard' && 'active'}`}>
                                <a className="sidenav-item-link" href='/dashboard'>
                                    <img src="../assets/img/user/dashboard.png" class="mdi"/>
                                    {/*<i className={'lk-nw mdi lk-nw mdi-desktop-mac-dashboard'}></i>*/}
                                    <span className="nav-text">Dashboard</span>
                                </a>
                            </li>

                            <li className={`has-sub ${currentPath == '/purchaseTest' && 'active'}`}>
                                <a className="sidenav-item-link" href='/purchaseTest'>
                                    <img src="../assets/img/user/purchase-test.png" class="mdi"/>
                                    {/* <i className={'lk-nw mdi lk-nw mdi-cart'}></i>*/}
                                    <span className="nav-text"> Purchase Test</span>
                                </a>
                            </li>
                            <li className={`has-sub ${currentPath == '/transactions' && 'active'}`}>
                                <a className="sidenav-item-link" href='/transactions'>
                                    <img src="../assets/img/user/transactions.png" class="mdi"/>
                                    {/*<i className={'lk-nw mdi lk-nw mdi-table-large'}></i>*/}
                                    <span className="nav-text"> My Transactions</span>
                                </a>
                            </li>
                            <li className={`has-sub ${currentPath == '/testHistory' && 'active'}`}>
                                <a className="sidenav-item-link" href='/testHistory'>
                                    <img src="../assets/img/user/previous-history.png" class="mdi"/>
                                    {/*<i className={'lk-nw mdi lk-nw mdi-table-settings'}></i>*/}
                                    <span className="nav-text"> Previous History</span>
                                </a>
                            </li>
                            {loginUser && loginUser.userType == 'Inhouse Student' ?
                                <li className={`has-sub ${currentPath == '/practiseTest' && 'active'}`}>
                                    <a className="sidenav-item-link" href='/practiseTest'>
                                        <img src="../assets/img/user/transactions.png" class="mdi"/>
                                        {/* <i className={'lk-nw mdi lk-nw mdi-table-settings'}></i>*/}
                                        <span className="nav-text"> Practise Test</span>
                                    </a>
                                </li> : null
                            }


                            <li className={`has-sub ${currentPath == '/myProfile' && 'active'}`}>
                                <a className="sidenav-item-link" href='/myProfile'>
                                    <img src="../assets/img/user/Profile.png" class="mdi"/>
                                    {/*<i className={'lk-nw mdi lk-nw mdi-face-profile '}></i>*/}
                                    <span className="nav-text"> My Profile</span>
                                </a>
                            </li>
                            <li className={`has-sub ${currentPath == '/changePassword' && 'active'}`}>
                                <a className="sidenav-item-link" href='/changePassword'>
                                    <img src="../assets/img/user/password-change.png" class="mdi"/>
                                    {/*<i className={'lk-nw mdi lk-nw mdi-textbox-password'}></i>*/}
                                    <span className="nav-text"> Change Password</span>
                                </a>
                            </li>
                            <li className="has-sub">
                                <a className="sidenav-item-link" onClick={() => logoutFxn()}>
                                    <img src="../assets/img/user/logout-exit.png" class="mdi"/>
                                    {/*<i className={'lk-nw mdi lk-nw mdi-logout'}></i>*/}
                                    <span className="nav-text">Logout</span>
                                </a>
                            </li>

                        </ul>

                    </div>

                    <hr className="separator"/>

                </div>
            </aside>

            <div className="page-wrapper">
                <header className="main-header " id="header">
                    <nav className="navbar navbar-static-top navbar--lg">
                        <button id="sidebar-toggler" className="sidebar-toggle">
                            <span className="sr-only">Toggle navigation</span>
                        </button>
                        <div className="search-form d-none d-lg-inline-block">

                        </div>

                        <div className="navbar-right ">
                            <ul className="nav navbar-nav">
                                <li className="dropdown user-menu">
                                    <button className="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                                        <img alt="User Image" className="user-image" src="../assets/img/user.png"/>
                                        <span className="d-none d-lg-inline-block">{loginUser.name}</span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li className="dropdown-header">
                                            <img alt="User Image" className="img-circle"
                                                 src="../assets/img/user.png"/>
                                            <div className="d-inline-block">
                                                {loginUser.name}
                                                <small className="pt-1">{loginUser.email}</small>
                                            </div>
                                        </li>

                                        <li>
                                            <a href="/myProfile">
                                                <i className="lk-nw mdi lk-nw mdi-account"></i> My Profile
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/changePassword">
                                                <i className="lk-nw mdi lk-nw mdi-key"></i> Change Password
                                            </a>
                                        </li>
                                        {/*  <li>
                                            <a href="#"> <i className="lk-nw mdi lk-nw mdi-diamond-stone"></i> Projects </a>
                                        </li>
                                        <li>
                                            <a href="#"> <i className="lk-nw mdi lk-nw mdi-settings"></i> Account Setting </a>
                                        </li>*/}

                                        <li className="dropdown-footer">
                                            <a onClick={() => logoutFxn()}> <i
                                                className="lk-nw mdi lk-nw mdi-logout"></i> Log Out </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </header>
                <div className="content-wrapper">
                    {children}
                </div>

            </div>
        </div>
    )
}

const DashboardLayoutRoute = ({component: Component, ...rest}) => {
    currentPath = rest.path;
    return (
        <Route {...rest} render={matchProps => (
            <DashboardLayout>
                <Component {...matchProps}  />
                <NotificationContainer/>
            </DashboardLayout>
        )}/>
    )
};

export default DashboardLayoutRoute;
