import React, {Component} from 'react';

import _ from 'lodash';
import AudioPlayer from "./audioPlayer";

class FillInTheBlanks_15 extends React.Component {

    state = {val: null, reorderOptions: []};

    constructor(props) {
        super(props)
        this.audioPlayerRef = React.createRef()

    }

    componentDidMount() {

        let arr = [];
        _.each(this.props.info.reorderParagraph, (val) => {
            arr.push({
                value: null,
                answerOrderNo: null,
            })
        })
        this.setState({reorderOptions: arr})

    }

    setValue = (value, index) => {

        let {reorderOptions} = this.state;
        _.each(reorderOptions, (val, ind) => {
            if (ind == index) {
                val.value = value;
                val.answerOrderNo = index + 1;
            }
        })


    }


    getQuestionAnswer = () => {
        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }

        this.props.moveNext()
        let {reorderOptions} = this.state;
        this.props.sampleObj({reorderOptions})
    }

    render() {

        const {info} = this.props;
        const {reorderOptions} = this.state;


        return (<div className={'row'}>

                <div className={'row col-md-12 mt-5'}>
                    <div className="col-md-2"></div>
                    <div className="col-md-8">

                        <AudioPlayer ref={this.audioPlayerRef} fileUrl={info.audiourl} onAudioComplete={() => {

                        }}/>
                    </div>
                    <div className="col-md-2"></div>
                </div>

                <div className="mt-3 col-md-12 col-lg-12 col-xl-12 wrtn-que">
                    {info.textArray.map((val, index) => {
                        return (
                            <span key={index}>

                                {val}{reorderOptions[index] ?
                                    <input defaultValue={reorderOptions[index].value} onChange={(e) => {
                                        this.setValue(e.target.value, index)
                                    }}/> : null}

                            </span>
                        )
                    })}
                </div>


            </div>
        )
    }


}

export default FillInTheBlanks_15
