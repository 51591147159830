import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Request from './../../request'
import moment from "moment";
import lodash from "lodash";
import styles from "./styles.css"
import {NotificationManager} from "react-notifications";

class Dashboard extends React.Component {
    startTest = (item, key) => {
        let cloneData = lodash.clone(this.state.testArr);
        cloneData[key].isLoading = true;
        this.setState({testArr: cloneData})
        let CheckPermission = localStorage.getItem('audio')
        /*
        localStorage.setItem('attemptedQuestion', JSON.stringify(item.questions))
         */

        if (CheckPermission) {
            this.getSingleTestId(item.testId, async (resp) => {
                let {success, msg, newToken} = await
                    Request.startTest({studentTestId: item._id});


                if (newToken) {
                    localStorage.setItem('token', newToken);
                }
                if (success) {
                    window.location.href = '/viewQuestion/' + item._id
                }
            });
        } else {
            alert("Permission Denied!! Change your browser settings to use microphone")
            return;
        }


    }

    constructor() {
        super();
        this.state = {
            testHistory: [],
            testArr: [],
            totalAmount: 0,
        }
    }

    componentDidMount() {
        this.getTodayTest();


    }

    async getTodayTest() {
        let resp = await Request.getStudenTodayTest();

        this.setState({testArr: resp.data})

        this.getPaymentList();
        this.getTestHistory();
    }


    async getPaymentList() {
        let obj = {};
        let resp = await Request.studentTransactions(obj);
        this.setState({resultArr: resp.data})
    }

    async getTestHistory() {
        let obj = {};
        let resp = await Request.studentTestHistory(obj);
        this.setState({testHistory: resp.data})
    }



    async downloadResultPdf(data, index) {
        let cloneArr = lodash.clone(this.state.testHistory)
        cloneArr[index].isLoading = true;
        this.setState({testHistory: cloneArr})
        let resp = await Request.downloadResultPdf(data);
        if (resp) {
            cloneArr[index].isLoading = false;
            this.setState({testHistory: cloneArr});
            window.open(resp, 'Download')
        }
    }

    getSingleTestId(testId, callback) {
        Request.getSingleTest({id: testId}).then(
            ({data, error}) => {
                if (!error) {
                    localStorage.setItem('testData', JSON.stringify(data))
                    return callback('done')
                } else {
                    return callback('done')
                }
            }
        )
    }

    render() {
        const {testArr, resultArr, testHistory} = this.state;
        return (
            <div className="content content-white">

                {/*<div className="row mb-4">
                    <div className="col-xl-12 col-sm-12">
                        <h3 className="text-dark hed">Dashboard</h3>
                        <h6 className="shrt-des"></h6>
                    </div>
                </div>*/}

                {testArr && testArr.map((item, key) => {
                    return (
                        <div className="row mt-2 exm-hgt" key={key}>
                            <div className="col-xl-6 col-md-12" style={{padding: 0}}>
                                <div className="card card-default qusns" data-scroll-height="675">
                                    <h3>{item.testName}</h3>
                                    <p>There are 3 sections.</p>
                                    {item.questions && item.questions.length ?
                                        <p>There are {item.questions.length} Questions.</p> : ''}
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12" style={{padding: 0}}>
                                <div className="card card-default" data-scroll-height="675">
                                    <div className="recrd">

                                        <button type="button"
                                                onClick={() => (this.startTest(item, key))}
                                                disabled={item.isLoading}
                                                className="mb-1 btn btn-success">
                                            {
                                                item.isLoading ? 'PLEASE WAIT...' :
                                                    item.testStatus == 'Not Attempted' ? ' START TEST NOW' : 'RESUME TEST'
                                            }

                                        </button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}


                <div className="row mt-5 mb-2">
                    <div className="col-xl-6 col-md-12 prevexm">
                        <h3>Previous Exam</h3>
                        <div className="card card-default">
                            {testHistory && testHistory.length ?
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Test No</th>
                                        <th scope="col">Test Name</th>
                                        <th scope="col">Status</th>
                                       {/* <th scope="col">Test Date</th>*/}
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {testHistory.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td scope="row">
                                                    {index + 1}
                                                </td>
                                                <td scope="row">
                                                    {item.testNo}
                                                </td>

                                                <td>{item.testName}</td>
                                                <td>
                                                    {item.testStatus == 'Not Attempted' &&
                                                    <label
                                                        className='label label-info'>{item.testStatus}</label>}

                                                    {item.testStatus == 'Attempted' &&
                                                    <label
                                                        className='label label-warning'>{item.testStatus}</label>}
                                                    {item.testStatus == 'Submitted' &&
                                                    <label
                                                        className='label label-success'>{item.testStatus}</label>}

                                                    {item.testStatus == 'Checked' &&
                                                    <label
                                                        className='label label-danger'>{item.testStatus}</label>}
                                                </td>
                                               {/* <td>{moment(item.testDate).format('DD MMMM YYYY')}</td>*/}
                                                <td>
                                                    {item.testStatus == 'Checked' &&
                                                    <button className={'btn btn-new btn-info btn-sm'}
                                                            disabled={item.isLoading}
                                                            onClick={() => this.downloadResultPdf(item, index)}>
                                                        {item.isLoading ? 'Wait...' : 'Result'}
                                                    </button>
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>

                                </table> :
                                <h4 className={'align-center mb-4'}>No Previous Exam</h4>
                            }
                        </div>
                    </div>

                    <div className="col-xl-6 col-md-12 prevexm">
                        <h3>Payments</h3>
                        <div className="card card-default">
                            {resultArr && resultArr.length ?
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Paid Amount</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Mode</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {resultArr.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td scope="row">
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {item.status == 'Assigned' &&
                                                    <label
                                                        className='label label-info'>{item.status}</label>}

                                                    {item.status == 'Pending' &&
                                                    <label
                                                        className='label label-warning'>{item.status}</label>}
                                                    {item.status == 'Paid' &&
                                                    <label
                                                        className='label label-success'>{item.status}</label>}

                                                    {item.status == 'Canceled' &&
                                                    <label
                                                        className='label label-danger'>{item.status}</label>}

                                                </td>
                                                <td>{item.totalAmount}</td>
                                                <td>{moment(item.date).format('DD MMMM YYYY')}</td>
                                                <td>{item.mode}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>

                                </table> :
                                <h4 className={'align-center mb-4'}>No Transactions</h4>
                            }
                        </div>
                    </div>

                </div>


            </div>
        )
    }
}


export default Dashboard;


