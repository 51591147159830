import React, {Component} from 'react';
import QuestionDescription from '../../components/questions/questionDescription';
import ReadAloud from '../../components/questions/readAloud';
import RepeatSentence from '../../components/questions/repeatSentence';
import DescribeImage from '../../components/questions/describeImage';
import RetellLecture from '../../components/questions/retellLecture';
import AnswerShortQuestion from '../../components/questions/answerShortQuestion';
import SummarizeWrittenText from '../../components/questions/summarizeWrittenText';
import ReadingWritingFillInTheBlanks_8 from '../../components/questions/readingWritingFillInTheBlanks_8';


import MultipleChoiceChooseSingleAnswerOptions_9
    from '../../components/questions/multipleChoiceChooseSingleAnswerOptions_9';
import MultipleChoiceChooseMultipleAnswerOptions_10
    from '../../components/questions/multipleChoiceChooseMultipleAnswerOptions_10';
import ReorderPara_11 from '../../components/questions/reorderPara_11';
import FillInTheBlank_12 from '../../components/questions/fillInTheBlank_12';

import SummarizeSpokenText_13 from '../../components/questions/summarizeSpokenText_13';
import MultipleChoiceChooseMultipleAnswerOptions_14
    from '../../components/questions/multipleChoiceChooseMultipleAnswerOptions_14';
import ListeningFillInTheBlanks_15 from '../../components/questions/listeningFillInTheBlanks_15';
import HighlightCorrectSummary_16 from '../../components/questions/highlightCorrectSummary_16';
import MultipleChoiceChooseSingleAnswerOptions_17
    from '../../components/questions/multipleChoiceChooseSingleAnswerOptions_17';
import SelectMissingWord_18 from '../../components/questions/selectMissingWord_18';
import HighlightIncorrectWord_19 from '../../components/questions/highlightIncorrectWord_19';
import Wfd_20 from '../../components/questions/wfd_20';
import Essay from '../../components/questions/essay';
import BreakTime from '../../containers/test/breakTime';
import Request from '../../request';
import {taskDetails} from '../../settings';
import Countdown from "react-countdown-now";
import styles from "../../index.css";
import _ from "lodash";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import async from 'async';

let ti = 0;
let preQuestId = null;
let currentTimeValue = null;

const questionCount = {
    'Speaking & Writing': [1, 2, 3, 4, 5, 6, 7],
    'Reading': [8, 9, 10, 11, 12],
    'Summarize Spoken Text': [13],
    'Listening': [14, 15, 16, 17, 18, 19, 20]
}
const typeList = ['Speaking & Writing', 'Reading', 'Summarize Spoken Text', 'Listening']

class ViewQuestion extends React.Component {

    state = {
        info: null,
        currentTask: 0,
        currentQuestion: 0,
        questionId: null,
        testId: null,
        totalQuestion: 0,
        timerValue: null,
        totalQuestionGroupCount: {},
        attemptedQuestionGroupCount: {},
        selectedQuestionSection: null,
        timerStatus: 'no',
        currentSectionAttempt: 1,
        currentSectionTotal: 0,
        payment: null,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    }
    nextQuestion = () => {
        if (this.ref) {
            this.ref.current.getQuestionAnswer();
        }

    }

    renderQuestionBody = (info) => {
        const {taskId} = info;
        switch (taskId) {
            case 1:
                return <ReadAloud ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>
            case 2:
                return <RepeatSentence ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>
            case 3:
                return <DescribeImage ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>
            case 4:
                return <RetellLecture ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>
            case 5:
                return <AnswerShortQuestion ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer}
                                            info={info}/>
            case 6:
                return <SummarizeWrittenText ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer}
                                             info={info}/>
            case 7:
                return <Essay ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>
            case 8:
                return <ReadingWritingFillInTheBlanks_8 ref={this.ref} moveNext={this.moveNext}
                                                        sampleObj={this.saveAnswer} info={info}/>
            case 9:
                return <MultipleChoiceChooseSingleAnswerOptions_9 ref={this.ref} moveNext={this.moveNext}
                                                                  sampleObj={this.saveAnswer}
                                                                  info={info}/>
            case 10:
                return <MultipleChoiceChooseMultipleAnswerOptions_10 ref={this.ref} moveNext={this.moveNext}
                                                                     sampleObj={this.saveAnswer}
                                                                     info={info}/>
            case 11:
                return <ReorderPara_11 ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>
            case 12:
                return <FillInTheBlank_12 ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer}
                                          info={info}/>
            case 13:
                return <SummarizeSpokenText_13 info={info} ref={this.ref} moveNext={this.moveNext}
                                               sampleObj={this.saveAnswer}/>
            case 14:
                return <MultipleChoiceChooseMultipleAnswerOptions_14 info={info} ref={this.ref} moveNext={this.moveNext}
                                                                     sampleObj={this.saveAnswer}/>
            case 15:
                return <ListeningFillInTheBlanks_15 info={info} ref={this.ref} moveNext={this.moveNext}
                                                    sampleObj={this.saveAnswer}/>
            case 16:
                return <HighlightCorrectSummary_16 info={info} ref={this.ref} moveNext={this.moveNext}
                                                   sampleObj={this.saveAnswer}/>
            case 17:
                return <MultipleChoiceChooseSingleAnswerOptions_17 ref={this.ref} moveNext={this.moveNext}
                                                                   sampleObj={this.saveAnswer}
                                                                   info={info}/>
            case 18:
                return <SelectMissingWord_18 ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer}
                                             info={info}/>
            case 19:
                return <HighlightIncorrectWord_19 ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer}
                                                  info={info}/>
            case 20:
                return <Wfd_20 ref={this.ref} moveNext={this.moveNext} sampleObj={this.saveAnswer} info={info}/>

            case 21:
                return <BreakTime ref={this.ref} info={info} breakTimer={this.state.breakTimer}
                                  timerClose={this.timerClose}/>

            default:
                return null
        }
    }
    saveAnswer = async (sample) => {
        const {testId, payment} = this.state;
        let finalObj = {
            testId,
            questionId: preQuestId,
            currentTask: sample.taskId,
            answers: sample
        }
        if (payment && payment.paidAmount > 0) {
            let response = await Request.saveAnswer(finalObj);
        }
    }

    timerClose = () => {
        Request.removeBreakTimer({_id: this.state.testId}).then((resp) => {
            this.checkBreakExist();
        })
    }

    setBreakTimer = async () => {
        let resp = await Request.setBreakTimer({_id: this.state.testId});
        if (resp.success) {
            this.setState({isBreakTime: resp.isBreakTime, breakTimer: resp.breakTimer})
        }
    }
    updateBreakTimerStatus = async (obj) => {
        let resp = await Request.updateBreakTimerStatus({_id: this.state.testId});
        if (resp.success) {
            obj.isBreakTime = resp.isBreakTime;
            obj.breakTimer = resp.breakTimer;
            // this.setState({isBreakTime: resp.isBreakTime, breakTimer: resp.breakTimer})
            this.setState(obj)
        }
    }
    checkBreakExist = async () => {
        if (this.state.testId) {
            let resp = await Request.getBreakTimer({_id: this.state.testId});
            if (resp.success) {
                this.setState({breakTimer: resp.breakTimer, isBreakTime: resp.isBreakTime});
                if (resp.isBreakTime && resp.breakTimer && !resp.breakTimer.endTimer) {
                    let obj1 = localStorage.getItem('listeningBlock') ? JSON.parse(localStorage.getItem('listeningBlock')) : null;
                    this.setState(obj1);
                }
            }
        }
    }
    moveNext = async (move = null) => {
        const {user} = this.state;
        const {match: {params}} = this.props;
        const {testId} = params;

        this.setState({
            info: null,
            testId: testId,
            timerValue: null

        }, () => {

        })
        const {questionId} = this.state;
        preQuestId = questionId;
        let finalObj = {
            testId,
            questionId,
            move,
            timerValue: currentTimeValue
        }

        let {success, question, submitted, timerValue, currentQuestion, currentSectionAttempt, currentSectionTotal, isBreakTime, payment, viewTest, message} = await Request.moveNext(finalObj);
        if (viewTest) {
            if (submitted) {
                localStorage.removeItem('timerStatus');
                this.props.history.push('/testSubmit/' + testId)
            } else {
                if (success && question) {
                    localStorage.setItem('currentTask', question.taskId);
                    let obj1 = {
                        info: question,
                        questionId: question.questionId,
                        currentTask: question.taskId,
                        currentQuestion: currentQuestion,
                        timerValue,
                        currentSectionAttempt,
                        currentSectionTotal,
                        payment
                    }

                    if (question.section == "Listening") {
                        localStorage.setItem('listeningBlock', JSON.stringify(obj1));
                        if (!isBreakTime) {
                            this.takeBreak((resp) => {
                                if (resp == 'yes') {
                                    this.setBreakTimer();
                                } else {
                                    this.updateBreakTimerStatus(obj1);
                                }
                            })
                        } else {
                            this.checkBreakExist();
                        }
                    } else {
                        this.setState(obj1)
                    }
                }
            }
        } else {
            window.location.href = '/dashboard'
        }
    }

    takeBreak = (callback) => {
        confirmAlert({
            title: 'You have finished section two.',
            message: 'If you want to take a 10 minutes scheduled break, press YES. Otherwise, please press NO to continue.',
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        return callback('yes')
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return callback('no')
                    }
                }
            ]
        });
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    async componentDidMount() {
        this.moveNext('no')
        this.checkBreakExist();
    }

    onTick = async (v) => {
        currentTimeValue = v.total;
        if (ti == 0) {
            ti = 5;

            let {success, data} = await Request.saveElapsedTime({
                mins: v.total,
                testId: this.state.testId,
                taskId: this.state.currentTask
            });
        } else {

            ti = ti - 1;
        }

    }

    componentWillUnmount() {
        currentTimeValue = null
    }

    render() {

        const {info, timerValue, isBreakTime, breakTimer, currentSectionAttempt, currentSectionTotal, payment} = this.state;


        return (
            <div className="content-wrapper">
                <div className={'positionOut1'}>
                    {
                        info && info.section ? <React.Fragment>
                            <div style={{color: 'black'}}>
                                {info.taskId == 13 ? info.taskName : info.section} - {currentSectionAttempt} out
                                of {currentSectionTotal}
                            </div>
                        </React.Fragment> : ''
                    }


                </div>


                <div className="content quecontent">
                    {isBreakTime && breakTimer && breakTimer.endTimer ?
                        this.renderQuestionBody({taskId: 21, _id: this.state.testId})
                        : <React.Fragment>
                            {
                                info && info.taskId && taskDetails[info.taskId] ?
                                    <QuestionDescription taskName={taskDetails[info.taskId].name}
                                                         description={taskDetails[info.taskId].description}/> : null
                            }
                            {
                                info && info.taskId ? this.renderQuestionBody(info) : <b>....</b>
                            }
                        </React.Fragment>
                    }


                </div>
                {((isBreakTime && breakTimer && !breakTimer.endTimer) || isBreakTime == undefined) &&
                <div className="footer mt-auto new-footer" styles="text-align: center;">
                    <div className="row flexRow">
                        <div className="col-md-6 col-lg-6 col-xl-4">

                        </div>

                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <p>


                                {
                                    timerValue ?
                                        <Countdown
                                            date={Date.now() + timerValue}
                                            onComplete={this.nextQuestion}
                                            onTick={this.onTick}
                                            renderer={({hours, minutes, seconds, completed}: CountdownRenderProps) => {
                                                return (
                                                    <span>
                                                        {hours ? <strong>{hours}
                                                            <small> hour</small>
                                                            : </strong> : null}
                                                        {minutes ? <strong>{minutes}
                                                            <small> min</small>
                                                            : </strong> : null}
                                                        <strong>{seconds}
                                                            <small> seconds</small></strong>
                                                    </span>
                                                    /* <div><span><strong>00</strong> hrs <strong>55</strong> Mins Left</span></div>*/
                                                );
                                            }}/> : null
                                }


                            </p>

                        </div>

                        <div className="col-md-6 col-lg-6 col-xl-4 next-btn">


                            {
                                info && info.taskId ?
                                    <button type="button" className="btn btn-success"
                                            onClick={_.debounce(() => {
                                                this.nextQuestion();
                                            }, 1000)}>
                                        Next<i className=" mdi mdi-chevron-right mr-1"></i></button> : null
                            }
                            {
                                info && info.taskId && payment && payment.paidAmount == 0 ?
                                    <a className="btn btn-danger myBtnDanger" href={'/dashboard'}>
                                        Exit</a> : null
                            }

                        </div>
                    </div>


                </div>


                }

            </div>
        )
    }


}

/*

const ViewQuestionCC = connect(
    mapStateToProps
)(ViewQuestion)
*/

export default ViewQuestion
