import React, {Component} from 'react'
import Countdown from "react-countdown-now";
import Beep from './beep'
import {ReactMic} from 'react-mic';
import moment from 'moment';
import 'rc-notification/assets/index.css';
import Notification from 'rc-notification';
import _ from 'lodash';

let notification = null;
Notification.newInstance({}, (n) => notification = n);

function skipFxn() {
    notification.notice({
        prefixCls: 'test',
        content: <div className={'pausenotify'}>Question Skipped</div>,
        onClose() {

        },
    });
}

let audioContent = null;
let skipped = false;
let recognition = null;


class AudioRecorder extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            record: false,
            status: 'Beginning',
            recordingTime: 0,
        }
        this.beepRef = React.createRef();

    }

    componentDidMount() {
        audioContent = null;
        window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
        if (!window.SpeechRecognition) {
            if (!window.webkitSpeechRecognition) {
                return
            }
            window.SpeechRecognition = window.webkitSpeechRecognition
        }
        // window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
        recognition = new window.SpeechRecognition();


    }

    componentWillUnmount = () => {


        audioContent = null;
        skipped = false;
        recognition = null;


    };

    clearTimer = () => {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;

        }
    }


    startRecording = () => {

        this.beepRef.current.playBeep();


        this.setState((state, props) => ({
            record: true,
            recordingTime: props.recordingDuration
        }), () => {


        })

        if (recognition) {
            recognition.interimResults = true;

            let firstStart = 0;
            recognition.start();

            recognition.onstart = () => {

                if (this.timerHandle) {
                    return;
                }

                this.timerHandle = setTimeout(() => {
                    if (!firstStart) {

                        skipFxn();
                        skipped = true;
                        recognition.stop()
                        this.stopRecording()

                    }
                }, 3900)
            }

            recognition.onspeechstart = () => {

                this.clearTimer()

                firstStart = 1
            };
            recognition.onspeechend = () => {

                firstStart = 0;
            }

            recognition.onend = () => {

                firstStart = 0;
                if (recognition) {
                    recognition.start()
                }


            };
        }

    }

    stopRecording = () => {

        if (recognition) {
            recognition.stop()
        }
        recognition = null;

        this.clearTimer()
        if (this.state.record) {
            this.setState({record: false});
        } else {
            this.props.submitRecording(audioContent)
        }

    }


    onData = (recordedBlob) => {


    }

    onStop = (recordedBlob) => {

        audioContent = recordedBlob;

        if (skipped) {
            audioContent = null;
        }

        this.props.submitRecording(audioContent);

    }


    render() {
        let {preparationDuration} = this.props;
        let {recordingTime} = this.state;


        return (
            <div className={'row'}>

                <div className={'col-md-12 '}>
                    <Beep ref={this.beepRef}/>

                    {
                        preparationDuration ? <Countdown date={Date.now() + preparationDuration}
                                                         onComplete={() => this.startRecording()}

                                                         renderer={({hours, minutes, seconds, completed}: CountdownRenderProps) => {
                                                             if (completed) {
                                                                 return (
                                                                     <div className="p-2  border recrding">
                                                                         <p className={'text-center '}>Recording Now
                                                                         </p>
                                                                     </div>
                                                                 );
                                                             }
                                                             return (
                                                                 <div className="p-2 border recrding">
                                                                     <p className={'text-center '}>Recording Beginning
                                                                         In {minutes ?
                                                                             <span>{minutes} : </span> : null} {seconds}</p>
                                                                 </div>
                                                             );
                                                         }}>

                        </Countdown> : ''
                    }


                    <div>
                        <div className={'record-vid'}>
                            <ReactMic
                                record={this.state.record}
                                className="sound-wave"
                                onStop={this.onStop}
                                onData={this.onData}
                                strokeColor="green"
                            />

                        </div>
                        <div className={'recodingmsg'}>
                            {
                                recordingTime ? <Countdown date={Date.now() + recordingTime}
                                                           onComplete={() => this.stopRecording()}
                                                           renderer={({hours, minutes, seconds, completed}: CountdownRenderProps) => {
                                                               if (completed) {
                                                                   return (
                                                                       <div className="recrding-now text-center ">
                                                                           Recording done
                                                                       </div>
                                                                   );


                                                               }
                                                               return (
                                                                   <div className="recrding-now text-center ">


                                                                       Finish In {minutes ?
                                                                       <span>{minutes} : </span> : null} {seconds}
                                                                   </div>
                                                               );
                                                           }}>

                                </Countdown> : null
                            }

                        </div>
                    </div>


                </div>

            </div>

        )
    }
}

export default AudioRecorder
