import React, {Component} from 'react'
import {Link} from 'react-router-dom'


export default function About() {
    return (
        <div className="container">
            <h1>About </h1>
            <p>
                <Link to="/">Dashboard</Link>
            </p>
        </div>
    )
}
