import React, {Component} from 'react';
import AudioPlayer from "./audioPlayer";
import {API_URL} from "../../request";
import renderHTML from 'react-render-html';

import _ from 'lodash';

class HighlightIncorrectWord_19 extends React.Component {

    state = {
        val: null,
        arr: []
    };

    constructor(props) {
        super(props)
        this.audioPlayerRef = React.createRef()

    }

    componentDidMount() {

        let test = [];
        let rawbody = this.props.info.body;
        rawbody = this.strip_html_tags(rawbody)

        rawbody.split(" ").map((val) => {
            test.push({txt: val, clicked: false});
        })


        this.setState({arr: test})


    }

    clicked = (c, v) => {
        const {arr} = this.state;
        let findIn = _.find(arr, (val, dd) => {
            return c.txt == val.txt && v == dd;

        })

        if (findIn) {
            findIn.clicked = findIn.clicked ? false : true
            this.setState({arr})
        }


    }

    getQuestionAnswer = () => {

        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }
        this.props.moveNext()

        let {arr} = this.state;

        arr = _.filter(arr, x => x.clicked == true)


        let options = []
        _.each(arr, (val) => {
            options.push({value: val.txt, optionId: 'NA'})
        })

        this.props.sampleObj({
            options: options
        })


    }
    strip_html_tags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    render() {

        const {info} = this.props;
        const {arr = []} = this.state;


        return (<div className={'row'}>
                <div className={'row col-md-12'}>
                    <div className={'row col-md-4'}></div>
                    <div className={'row col-md-4 mt-3'}>
                        <AudioPlayer ref={this.audioPlayerRef} fileUrl={info.audiourl} onAudioComplete={() => {

                        }}/>
                    </div>
                    <div className={'row col-md-4'}></div>
                </div>

                <div className={'row col-md-12 wrtn-que'}>
                    {
                        arr.map((val, index) => {
                            return <span className={val.clicked ? 'highlight' : 'cusrso'}
                                         onClick={() => this.clicked(val, index)}
                                         key={index}> {val.txt} </span>
                        })
                    }


                </div>


            </div>
        )
    }


}

export default HighlightIncorrectWord_19
