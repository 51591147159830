import React, {Component} from 'react';
import CutCopyPaste from "./cutcopypaste";
import renderHTML from "react-render-html";


class SummarizeWrittenText extends React.Component {

    state = {val: '', totalWords: 0};

    constructor(props) {
        super(props)
    }

    getQuestionAnswer = () => {
        this.props.moveNext()
        let {val} = this.state;
        this.props.sampleObj({text: val ? val : null})
    }


    setTextValue = (val) => {

        let totalWords = 0
        let wordsArr = []
        if (val) {
            wordsArr = val.split(' ');
        }

        this.setState({val: val, totalWords: wordsArr.length})
    }

    render() {

        const {info} = this.props;
        const {totalWords} = this.state;
        info.answerDuration = info.answerDuration * 1000;
        return (<div className={'row'}>


                <div className={'col-md-12 mt-3 mb-3 col-lg-12 col-xl-12'}>

                    {/* <div className={'row'}>
                        <div className={'col-md-4 mt-3 mb-3 col-lg-4 col-xl-4'}></div>
                        <div className={'col-md-4 mt-3 mb-3 col-lg-4 col-xl-4'}>
                            <AudioRecorder preparationDuration={info.preparationDuration}
                                           recordingDuration={info.preparationDuration}/>
                        </div>
                        <div className={'col-md-4 mt-3 mb-3 col-lg-4 col-xl-4'}></div>

                    </div>*/}
                </div>
                <div className="row col-md-12">
                    <div className="col-md-12 col-lg-12 col-xl-12 wrtn-que">
                        {
                            info.body ? renderHTML(info.body) : ''
                        }
                    </div>
                </div>

                <div className="row col-md-12 mt-4">
                    <div className={'col-md-12 text-right'}>
                        <span>Total Words: {totalWords}</span>

                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <textarea value={this.state.val} rows="4" style={{width: '100%', padding: 10}}
                                  spellCheck={false}
                                  onChange={(e) => this.setTextValue(e.target.value)}></textarea>
                    </div>
                </div>

                <CutCopyPaste pasteTxt={(txt) => {
                    let {val} = this.state;
                    this.setTextValue(val + ' ' + txt)
                }}/>

            </div>
        )
    }


}


export default SummarizeWrittenText
