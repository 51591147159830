import React, {Component} from 'react'

import AudioPlayer from "./audioPlayer";
import CutCopyPaste from "./cutcopypaste";
import {API_URL} from "../../request";

class SummarizeSpokenText_13 extends React.Component {

    state = {
        audioDuration: 0,
        val: '',
        totalWords: 0
    };

    constructor(props) {
        super(props)
        this.audioPlayerRef = React.createRef()
    }


    componentDidMount() {
    }

    setTextValue = (val) => {
        let totalWords = 0
        let wordsArr = []
        if (val) {
            wordsArr = val.split(' ');
        }

        this.setState({val: val, totalWords: wordsArr.length})
    }

    getQuestionAnswer = () => {

        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }

        this.props.moveNext()
        let {val} = this.state;
        this.props.sampleObj({text: val ? val : null})

    }


    render() {

        const {info} = this.props;
        const {totalWords} = this.state;


        return (<div className={'row'}>
                <div className={'row col-md-12 mt-5 mb-3 '}>
                    <div className={'col-md-4'}></div>
                    <div className={'col-md-4'}>
                        <div className={'mb-3'}>
                            <AudioPlayer ref={this.audioPlayerRef} fileUrl={info.audiourl} onAudioComplete={() => {

                            }}/>

                        </div>
                    </div>
                    <div className={'col-md-4'}>

                    </div>
                </div>
                <div className={'row col-md-12 mt-2 mb-3 '}>
                    <div className="row col-md-12 mt-4">
                        <div className={'col-md-12 text-right'}>
                            <span>Total Words: {totalWords}</span>

                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <textarea rows="4" value={this.state.val} style={{width: '100%', padding: 10}}
                                      spellCheck={false}
                                      onChange={(e) => this.setTextValue(e.target.value)}></textarea>
                        </div>
                    </div>

                    <CutCopyPaste pasteTxt={(txt) => {
                        let {val} = this.state;
                        this.setTextValue(val + ' ' + txt)
                    }}/>
                </div>


            </div>
        )
    }
}

export default SummarizeSpokenText_13
