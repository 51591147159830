import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/dist/react-notifications.css'
import {taskDetails} from './settings'
import styles from './index.css'
import _ from 'lodash'

const arrData = {
    part1: [],
    part2: [],
    part3: []
}
const loginUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
const logoutFxn = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.reload()
}
const setMenuFxn = () => {
    _.each(taskDetails, (item, key) => {
        if (key <= 7) {
            arrData.part1.push({id: parseInt(key), task: item.name})
        }
        if (key > 7 && key <= 12) {
            arrData.part2.push({id: parseInt(key), task: item.name})
        }
        if (key > 12) {
            arrData.part3.push({id: parseInt(key), task: item.name})
        }

    })
}

setMenuFxn();
const menu = [{
    section: 'Speaking & Writing',
    part: 'Part 1',
    arr: [] /* [
        {task: 'Read Aloud', id: 1,},
        {task: 'Repeat Sentence', id: 2},
        {task: 'Describe Image', id: 3},
        {task: 'Retell Lecture', id: 4},
        {task: 'Answer Short Question', id: 5},
        {task: 'Summarize Written Text', id: 6},
        {task: 'Essay', id: 7,},
        {task: 'Summarize Spoken Text', id: 8,},
        {task: 'Multiple Choice, Multiple Answer', id: 14},
        {task: 'Fill in the Blanks', id: 15},
        {task: 'Highlight Correct Summary', id: 16},
        {task: 'Multiple Choice, Single Answer', id: 17},
        {task: 'Select Missing Word', id: 18},
        {task: 'Highlight Incorrect Words', id: 19},
        {task: 'Write From Dictation', id: 20},

    ]*/
},
    /* {
         section: 'Reading',
         part: 'Part 2',
         arr: [

         ]
     },
     {
         section: 'Listening',
         part: 'Part 3',
         arr: [

         ]
     }*/
]

const TestLayout = ({children}) => {
    let currentTask = localStorage.getItem('currentTask') ? JSON.parse(localStorage.getItem('currentTask')) : null;

    let testt = () => {
        console.log('ssssd')
    }

    testt();

    return (
        <div className="page page-dashboard">
            <aside className="left-sidebar bg-sidebar">
                <div className="sidebar sidebar-with-footer" id="sidebar">

                    <div className="sidebar-scrollbar">

                        <ul className="nav sidebar-inner" id="sidebar-menu">


                            <li className="has-sub expand active ">
                                <a aria-controls="dashboard" aria-expanded="false" className="sidenav-item-link"
                                   data-target="#part1"
                                   data-toggle="collapse" href="javascript:void(0)">
                                    <img className="mdi" src="/assets/img/user/part-1.png"/>
                                    <span className="nav-text"><p>PART 1</p>Speaking & Writing</span>
                                </a>
                                <ul className="collapse show " data-parent="#sidebar-menu"
                                    id="part1">
                                    <div className="sub-menu">
                                        {arrData.part1.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a className="sidenav-item-link">
                                                        <span className="nav-text">{item.task}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}


                                    </div>
                                </ul>
                            </li>
                            <hr className={'hr1'}/>
                            <li className="has-sub expand active">
                                <a aria-controls="ui-elements" aria-expanded="false" className="sidenav-item-link"
                                   data-target="#part2"
                                   data-toggle="collapse" href="javascript:void(0)">
                                    <img className="mdi" src="/assets/img/user/part-2.png"/>
                                    <span className="nav-text"><p>PART 2</p>Reading</span>
                                </a>
                                <ul className="collapse show" data-parent="#sidebar-menu"
                                    id="part2">
                                    <div className="sub-menu">
                                        {arrData.part2.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a className="sidenav-item-link">
                                                        <span className="nav-text">{item.task}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}


                                    </div>
                                </ul>
                            </li>
                            <hr className={'hr1'}/>
                            <li className="has-sub expand active">
                                <a aria-controls="charts" aria-expanded="false" className="sidenav-item-link"
                                   data-target="#part3"
                                   data-toggle="collapse" href="javascript:void(0)">
                                    <img className="mdi" src="/assets/img/user/part-3.png"/>
                                    <span className="nav-text"><p>PART 3</p>Listening</span>
                                </a>
                                <ul className="collapse show" data-parent="#sidebar-menu"
                                    id="part3">
                                    <div className="sub-menu">

                                        {arrData.part3.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a className="sidenav-item-link">
                                                        <span className="nav-text">{item.task}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}


                                    </div>
                                </ul>
                            </li>
                        </ul>

                    </div>

                    <hr className="separator"/>

                </div>
            </aside>

            <div className="page-wrapper">
                <header className="main-header " id="header">
                    <nav className="navbar navbar-static-top navbar-expand-lg">
                        <button id="sidebar-toggler" className="sidebar-toggle">
                            <span className="sr-only">Toggle navigation</span>
                        </button>
                        <div className="search-form d-none d-lg-inline-block">

                        </div>

                        <div className="navbar-right ">
                            <ul className="nav navbar-nav">
                                <li className="dropdown user-menu">
                                    <button className="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                                        <img alt="User Image" className="user-image" src="/assets/img/user.png"/>
                                        <span className="d-none d-lg-inline-block">{loginUser.name}</span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li className="dropdown-header">
                                            <img alt="User Image" className="img-circle"
                                                 src="/assets/img/user.png"/>
                                            <div className="d-inline-block">
                                                {loginUser.name}
                                                <small className="pt-1">{loginUser.email}</small>
                                            </div>
                                        </li>


                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </header>
                <div className="content-wrapper">
                    {children}
                </div>

            </div>
        </div>
    )
}

const DashboardLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <TestLayout>
                <Component {...matchProps}/>
                <NotificationContainer/>
            </TestLayout>
        )}/>
    )
};

export default DashboardLayoutRoute;
