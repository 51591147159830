import React, {Component} from 'react';
import AudioPlayer from "./audioPlayer";
import _ from 'lodash';
import renderHTML from 'react-render-html';

class MultipleChoiceChooseMultipleAnswerOptions_14 extends React.Component {

    state = {val: null, options: []};

    constructor(props) {
        super(props)
        this.audioPlayerRef = React.createRef()

    }

    componentDidMount() {

        let options = []
        _.each(this.props.info.mcqOptions, (val) => {
            val.checked = false
            options.push(val)
        })
        this.setState({options})
    }


    checkOption = (val) => {

        let {options} = this.state;
        _.each(options, (vv) => {
            if (vv == val) {
                vv.checked = !vv.checked;
            }
        })

    }


    getQuestionAnswer = () => {

        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }


        this.props.moveNext()
        let {options} = this.state;
        options = _.filter(options, x => x.checked == true)
        if (options && options.length) {
            this.props.sampleObj({
                options: [{
                    value: options[0].value,
                    optionId: options[0]._id
                }]
            })
        } else {
            this.props.sampleObj({
                options: []
            })
        }
    }

    render() {

        const {info} = this.props;
        const {options} = this.state;

        return (<div className={'row'}>
                <div className="mt-3 col-md-7 col-lg-7 col-xl-7 wrtn-que">
                    <div className={'mb-3'}>

                        <AudioPlayer ref={this.audioPlayerRef} fileUrl={info.audiourl} onAudioComplete={() => {

                        }}/></div>
                </div>
                <div className="mt-3 col-md-5 col-lg-5 col-xl-5 wrtn-que">
                    <p className={'mcqhead'}>
                        Read the text and answer the multiple-choice question by selecting the correct response.
                        More than one answer can be correct.
                    </p>

                    {info.mcqBody ? <p>{renderHTML(info.mcqBody)}</p> : ''}

                    <div>
                        {options.map((val, index) => {
                            return (
                                <label key={index} className="control control-checkbox checkbox-default">{val.value}
                                    <input type="checkbox" value={val} onChange={() => {
                                        this.checkOption(val)
                                    }}/>
                                    <div className="control-indicator"></div>
                                </label>

                            )
                        })}
                    </div>
                </div>


            </div>
        )
    }


}

export default MultipleChoiceChooseMultipleAnswerOptions_14
