import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import Request from './../../request'
import Countries from './../../jsons/countries'
import CountryCode from './../../jsons/countryCode'
import States from './../../jsons/states'
import {NotificationManager} from "react-notifications";

class Signup extends React.Component {
    checkEmailIsUnique = async (event) => {
        event.preventDefault();
        if (!this.state.password || this.state.password.length < 6) {
            NotificationManager.warning('Please enter min 6 digit password.');
            return
        }
        if (this.state.password !== this.state.cpassword) {
            NotificationManager.warning('Password not match.');
            return
        }
        this.setState({isLoading: true})
        this.checkEmailFxn(this.state.email, (resp) => {
            if (resp.error) {
                NotificationManager.error(resp.msg);
                this.setState({isLoading: false})
            } else {
                this.setState({
                    formValue: 2
                })
                this.setState({isLoading: false})
            }
        })

    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.state.password || this.state.password.length < 6) {
            NotificationManager.warning('Please enter min 6 digit password.');
            return
        }
        if (this.state.password !== this.state.cpassword) {
            NotificationManager.warning('Password not match.');
            return
        }
        this.setState({isLoading: true})
        this.checkEmailFxn(this.state.email, async (resp) => {
            if (resp.error) {
                alert(resp.msg);
                this.setState({isLoading: false})
            } else {
                let obj = {
                    userType: 'Direct Student',
                    email: this.state.email,
                    mobile: this.state.mobile,
                    country: this.state.country,
                    countryCode: this.state.countryCode,
                    state: this.state.stateName,
                    city: this.state.city,
                    postcode: this.state.postcode,
                    address: this.state.address,
                    gender: this.state.gender,
                    dob: this.state.dob,
                    name: this.state.name,
                    password: this.state.password,
                    via: 'website'
                }
                let respObj = await Request.signup(obj);
                if (respObj.success) {
                    localStorage.setItem('token', respObj.token);
                    localStorage.setItem('user', JSON.stringify(respObj.data));
                    window.location.href = "/";
                } else {
                    NotificationManager.error(resp.msg);
                    this.setState({isLoading: false})
                }
            }

        })


    }

    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            country: '',
            countryCode: null,
            gender: 'Male',
            formValue: 1,
            stateArr: [],
            stateName: '',
            city: '',
            isLoading: false
        }
    }

    async checkEmailFxn(email, callback) {
        let resp = await Request.checkEmailIsUnique(this.state.email);
        return callback(resp);
    }

    chooseCountry(data) {
        let findCountryObj = lodash.find(Countries, (item) => {
            return item.name == data
        })
        let stateArr = []
        if (findCountryObj) {
            let stateArr = lodash.filter(States, (item) => {
                return item.country_id == findCountryObj.id
            })
            this.setState({
                stateArr: stateArr,
                country: data
            })
        } else {
            this.setState({
                stateArr: [],
                country: data
            })
        }
    }

    render() {
        const {stateArr, isLoading} = this.state
        return (
            <div className='bg-light-gray'>
                <div className="container d-flex flex-column justify-content-between vh-100">
                    <div className="row mt-3">
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <img src="assets/img/summit-logo.png" alt="Summit Language Learning"/>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10 text-right pt-2">
                            <button type="button" className="mb-1 btn btn-green"><i
                                className="mdi mdi-arrow-left"></i> Visit Website
                            </button>
                            {/* <button type="button" className="mb-1 btn btn-price">Test Pricing</button>*/}
                        </div>


                    </div>

                    <div className="row mt-2">
                        <div className="col-xl-6 col-lg-6 col-md-10 learn-frm">


                            <ul className="learn-lst mb-5">
                                {/*<li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>*/}
                            </ul>

                            <img src="assets/img/user/login.png" className="login-img mt-4"/>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <div className="card">
                                <div className="card-body login">
                                    <h3>Sign Up</h3>

                                    {this.state.formValue == 1 &&
                                    <div>
                                        <h6>Step 1 (Account Access Details)</h6>
                                        <hr/>
                                        <form onSubmit={this.checkEmailIsUnique}>
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <input type="text" className="form-control input-lg" id="name"
                                                           aria-describedby="nameHelp" value={this.state.name}
                                                           onChange={(e) => this.setState({name: e.target.value})}
                                                           required
                                                           placeholder="Name"/>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="email" className="form-control input-lg" id="email"
                                                           value={this.state.email}
                                                           required
                                                           onChange={(e) => this.setState({email: e.target.value})}
                                                           aria-describedby="emailHelp" placeholder="Email"/>
                                                </div>
                                                <div className="form-group col-md-12 ">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <select
                                                                className="form-control  form-control-select input-xs"
                                                                value={this.state.countryCode} required
                                                                onChange={(e) => this.setState({countryCode: e.target.value})}>
                                                                <option value={''}>Select Country Code</option>

                                                                {CountryCode.map((item, index) => (
                                                                    <option key={index} value={item.dial_code}>
                                                                        {item.code} ({item.dial_code})
                                                                    </option>
                                                                ))}


                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input type="text" className="form-control input-lg"
                                                                   id="mobile"
                                                                   value={this.state.mobile}
                                                                   required
                                                                   onChange={(e) => this.setState({mobile: e.target.value})}
                                                                   placeholder="Mobile No"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12 ">
                                                    <input type="password" className="form-control input-lg"
                                                           id="password"
                                                           value={this.state.password}
                                                           required
                                                           onChange={(e) => this.setState({password: e.target.value})}
                                                           placeholder="Password"/>
                                                </div>
                                                <div className="form-group col-md-12 mb-2">
                                                    <input type="password" className="form-control input-lg"
                                                           id="cpassword"
                                                           value={this.state.cpassword}
                                                           required
                                                           onChange={(e) => this.setState({cpassword: e.target.value})}
                                                           placeholder="Confirm Password"/>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-inline-block mr-3">
                                                        <label className="control control-checkbox">
                                                            <input type="checkbox" required/>
                                                            <div className="control-indicator"></div>
                                                            I Agree the terms and conditions
                                                        </label>

                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <button type="submit" disabled={isLoading}
                                                            className="btn btn-lg btn-primary btn-block">
                                                        {isLoading ? 'Please Wait...' : 'CONTINUE'}
                                                    </button>
                                                </div>
                                                <div className='col-md-6 align-center'>

                                                    <p>Already have an account? <br/>
                                                        <a className="text-blue" href="/login"> Sign in</a>
                                                    </p>
                                                </div>

                                            </div>
                                        </form>
                                    </div>}
                                    {this.state.formValue == 2 &&
                                    <div>
                                        <h6>Step 2 (Personal Details)</h6>
                                        <hr/>
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="form-group col-md-12 mb-4">
                                                    <input type="date" className="form-control input-lg" id="dob"
                                                           aria-describedby="nameHelp" value={this.state.dob}
                                                           onChange={(e) => this.setState({dob: e.target.value})}
                                                           required
                                                           placeholder="Date Of Birth"/>
                                                </div>
                                                <div className="form-group col-md-6 mb-1"
                                                     style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <label className="control outlined control-radio radio-success">Male
                                                        <input type="radio" name='gender' value={this.state.gender}
                                                               required
                                                               onChange={(e) => this.setState({gender: 'Male'})}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                    <label className="control outlined control-radio radio-success">Female
                                                        <input type="radio" name='gender' value={this.state.gender}
                                                               required
                                                               onChange={(e) => this.setState({gender: 'Female'})}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                <div className="form-group col-md-12 ">
                                                    <textarea type="text" className="form-control input-lg"
                                                              id="adress"
                                                              value={this.state.address}
                                                              required
                                                              onChange={(e) => this.setState({address: e.target.value})}
                                                              placeholder="Street Address"></textarea>
                                                </div>
                                                <div className="form-group col-md-6 ">
                                                    <select className="form-control form-control-select input-xs"
                                                            value={this.state.country}
                                                            required
                                                            onChange={(e) => this.chooseCountry(e.target.value)}>
                                                        <option value=''>Select Country</option>
                                                        {Countries.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}


                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6 ">
                                                    <select className="form-control form-control-select input-xs"
                                                            value={this.state.stateName}
                                                            required
                                                            onChange={(e) => this.setState({stateName: e.target.value})}>
                                                        {stateArr.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}


                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6 ">
                                                    <input type="text" className="form-control input-lg"
                                                           id="city"
                                                           value={this.state.city}
                                                           required
                                                           onChange={(e) => this.setState({city: e.target.value})}
                                                           placeholder="City"/>
                                                </div>
                                                <div className="form-group col-md-6 ">
                                                    <input type="text" className="form-control input-lg"
                                                           id="postcode"
                                                           value={this.state.postcode}
                                                           required
                                                           onChange={(e) => this.setState({postcode: e.target.value})}
                                                           placeholder="Post Code"/>
                                                </div>

                                                <div className='col-md-6'>
                                                    <button type="submit" disabled={isLoading}
                                                            className="btn btn-lg btn-primary btn-block mb-4">
                                                        {isLoading ? 'Please Wait...' : 'REGISTER'}
                                                    </button>
                                                </div>

                                            </div>
                                        </form>

                                    </div>}


                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="copyright pl-0">
                        <p className="text-center" style={{color: '#ffffffbf'}}>&copy; Copyright - Summit 2019 - PTE
                            Learning
                            Programme
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Signup;


