import React from 'react'
import Request from './../../request'
import {Redirect, Route} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';

class Login extends React.Component {

    userLogin = async (event) => {
        event.preventDefault();
        let obj = {
            resetPasswordkey: this.state.resetPasswordkey,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }
        if (obj.password.length < 6) {
            NotificationManager.warning('Please enter min 6 digit password.');
            return
        }
        if (obj.password !== obj.confirmPassword) {
            NotificationManager.warning('Confirm password not match.');
            return
        }
        let resp = await Request.resetPassword(obj);
        if (resp.success) {
            NotificationManager.success(resp.msg);
            this.setState({
                password: '',
                confirmPassword: '',
            })
            setTimeout(() => {
                window.location.href = "/login";
            }, 2000)

        } else {
            NotificationManager.error(resp.msg);
        }
    }

    constructor() {
        super();
        this.state = {
            resetPasswordkey: '',
            password: '',
            confirmPassword: ''
        }
    }

    componentDidMount() {
        let {key} = this.props.match.params;
        if (key) {
            this.setState({resetPasswordkey: key});
        }
    }

    render() {
        return (
            <div className='bg-light-gray'>
                <div className="container d-flex flex-column justify-content-between vh-100">
                    <div className="row mt-4">
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <img src="../assets/img/summit-logo.png" alt="Summit Language Learning"/>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10 text-right pt-2">
                            <button type="button" className="mb-1 btn btn-green"><i
                                className="mdi mdi-arrow-left"></i> Visit Website
                            </button>
                            {/*<button type="button" className="mb-1 btn btn-price">Test Pricing</button>*/}
                        </div>


                    </div>

                    <div className="row mt-4 mb-4">
                        <div className="col-xl-6 col-lg-6 col-md-10 learn-frm">


                            <ul className="learn-lst mb-4">
                                {/*<li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>
                                <li><i className="mdi mdi-play"></i>Lorem Ipsum is simply dummy text.</li>*/}
                            </ul>

                            <img src="../assets/img/user/login.png" className="login-img"/>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <div className="card">
                                <div className="card-body login">
                                    <h3>Reset Password</h3>
                                    <h6>to continue with summit PTE Exam</h6>
                                    <hr/>
                                    <form onSubmit={this.userLogin} className="mb-5">
                                        <div className="row log-frm">
                                            <div className="form-group col-md-11 mb-4">
                                                <input type="password" className="form-control input-lg" id="email"
                                                       value={this.state.password}
                                                       required
                                                       onChange={(e) => this.setState({password: e.target.value})}
                                                       aria-describedby="emailHelp" placeholder="New Password"/>
                                            </div>
                                            <div className="form-group col-md-11">
                                                <input type="password" className="form-control input-lg" id="password"
                                                       value={this.state.confirmPassword}
                                                       required
                                                       onChange={(e) => this.setState({confirmPassword: e.target.value})}
                                                       placeholder="Confirm Password"/>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex my-2 justify-content-between">
                                                    <div className="col-md-6 sub-btn">
                                                        <button type="submit" className="btn btn-lg mb-4">RESET</button>
                                                    </div>
                                                    <div className="col-md-6 align-center"><p>
                                                        Already have an account?
                                                        <a className="text-blue" href="/login"> Sign in</a>
                                                    </p>

                                                    </div>




                                                </div>



                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="copyright pl-0">
                        <p className="text-center" style={{color: '#ffffffbf'}}>&copy; Copyright - Summit 2019 - PTE
                            Learning
                            Programme
                        </p>
                    </div>
                </div>
            </div>
        )
    }

}

export default Login;


