import React, {Component} from 'react'
import Request from './../../request'
import lodash from "lodash";
import Countdown from "react-countdown-now";

class PaymentResponse extends React.Component {
    constructor() {
        super();
        this.state = {
            testHistory: [],
            testArr: [],
            totalAmount: 0,
        }
    }


    componentDidMount() {
        const {location: {search}} = this.props;
        let aa = new URLSearchParams(search);
        if (aa.get("checkoutId") && aa.get("transactionId")) {
            let obj = {
                checkoutId: aa.get("checkoutId"),
                transactionId: aa.get("transactionId")
            }
            this.updatePaymentAndCreateTest(obj);
        } else {
            window.location.href = `/paymentFail`
        }
    }

    async updatePaymentAndCreateTest(data) {
        let resp = await Request.updatePaymentAndCreateTest(data);
        if (resp.success) {
            window.location.href = '/paymentSuccess'
        } else {
            window.location.href = '/paymentFail'
        }

    }


    render() {
        return (
            <div className="content-wrapper-white">
                <div className="content breakTimer">
                    <img src={'../assets/img/paymentResponse.png'} style={{height: '250px'}}/>
                    <br/>
                    <div>
                        <br/>
                        <h4>Please wait... Payment on process (Please don't Reload Page)</h4>
                        <img src={'../assets/img/dotLoader.gif'} style={{'position': 'relative', top: '-30px'}}/>
                    </div>
                </div>

            </div>
        )
    }
}


export default PaymentResponse;


