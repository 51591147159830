import React, {Component} from 'react';
import _ from 'lodash';

class ReorderParagraph extends React.Component {

    state = {val: null, answerArr: [], queArr: []};

    constructor(props) {
        super(props)
    }

    componentDidMount() {

        this.setState({
            queArr: this.props.info.reorderParagraph,

        })


    }

    onDragStart = (ev, v) => {
        ev.dataTransfer.setData('id', JSON.stringify(v))

    }

    onAnswerDrag = (ev, v) => {
        ev.dataTransfer.setData('aid', JSON.stringify(v))

    }

    onAnsDrop = (ev) => {


        let obj = ev.dataTransfer.getData('id');
        if (obj) {
            obj = JSON.parse(obj);
            let {answerArr, queArr} = this.state;
            let findInd = _.find(answerArr, (va) => {
                return va._id == obj._id;
            })
            if (!findInd) {
                answerArr.push(obj)
                queArr = _.reject(queArr, (va) => {
                    return va._id == obj._id;
                })

            }
            answerArr = _.uniq(answerArr);
            queArr = _.uniq(queArr);
            this.setState({answerArr, queArr});
        }

    }

    getQuestionAnswer = () => {


        this.props.moveNext()

        let {answerArr} = this.state;
        let i = 1;
        _.each(answerArr, (v) => {
            v.actualOrderNo = v.orderNo;
            v.answerOrderNo = i;
            v.optionId = v._id;
            delete v.orderNo
            i = i + 1;
        })

        this.props.sampleObj({reorderOptions: answerArr})
    }


    onQueDrop = (ev) => {

        let obj = ev.dataTransfer.getData('aid');

        if (obj) {
            obj = JSON.parse(obj);
            let {answerArr, queArr} = this.state;
            let findInd = _.find(queArr, (va) => {
                return va._id == obj._id;
            })
            if (!findInd) {
                queArr.push(obj)
                answerArr = _.reject(answerArr, (va) => {
                    return va._id == obj._id;
                })

            }
            answerArr = _.uniq(answerArr);
            queArr = _.uniq(queArr);
            this.setState({answerArr, queArr});
        }

    }

    render() {


        const {queArr, answerArr} = this.state;
        return (<div className={'row col-md-12 text-center mt-3 justce'}>

                <div className="col-md-5 randoroder" onDragOver={(e) => {
                    e.preventDefault()
                }} onDrop={(e) => this.onQueDrop(e)}>

                    <div className={'sourchead'}><b>Source</b></div>

                    {queArr.map((val, index) => {
                        return (
                            <div key={index}
                                 draggable
                                 onDragStart={(e) => this.onDragStart(e, val)}
                                 className={'randli'}>{val.value}</div>
                        )
                    })}


                </div>
                <div className={'col-md-1 rightarrow'}>
                    <span className={'mdi mdi-arrow-right'}></span>
                </div>
                <div className="col-md-5 col-lg-5 col-xl-5 randoroder" onDragOver={(e) => {
                    e.preventDefault()
                }}
                     onDrop={(e) => this.onAnsDrop(e)}>
                    <div className={'answerHead'}><b>Answer</b></div>
                    {answerArr.map((val, index) => {
                        return (
                            <div key={index}
                                 draggable
                                 onDragStart={(e) => this.onAnswerDrag(e, val)}
                                 className={'randli'}>{val.value}</div>
                        )
                    })}
                </div>


            </div>
        )
    }


}

export default ReorderParagraph
