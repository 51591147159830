import React, {Component} from 'react';


class TestSumbitted extends React.Component {

    state = {}

    constructor(props) {
        super(props);

    }

    async componentDidMount() {
        const {match: {params}} = this.props;
        console.log(params)
        const {testId} = params;


    }


    render() {


        return (
            <div className="content-wrapper">
                <div className="content">


                    <div className="card card-default">
                        <div className="card-header card-header-border-bottom">
                            <h2>Test Submitted Successfully</h2>
                        </div>
                        <div className="card-body">
                           <span onClick={() => {
                               window.location.href = "/dashboard"
                           }} className="mb-2 mr-2 badge badge-primary">Home</span>

                        </div>

                    </div>
                </div>


            </div>
        )
    }


}

export default TestSumbitted
