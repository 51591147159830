import React, {Component} from 'react'


import AudioRecorder from "./audioRecorder";
import AudioPlayer from "./audioPlayer";

import Request from "../../request";

class AnswerShortQuestion extends React.Component {

    state = {
        recordingNow: false
    };

    constructor(props) {
        super(props)
        this.audioRef = React.createRef()
        this.audioPlayerRef = React.createRef()

    }


    componentDidMount() {
    }

    getQuestionAnswer = () => {

        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }

        if (this.audioRef && this.audioRef.current) {
            this.audioRef.current.stopRecording()
        } else {
            this.props.moveNext()
            this.props.sampleObj({recording: []})
        }

    }

    audioCompleted = () => {
        this.setState({recordingNow: true})
    }

    saveShortAnswer = async (obj) => {

        this.props.moveNext()
        if (obj) {
            let form = new FormData();
            form.append('file', obj ? obj.blob : null);
            let respp = await Request.uploadAudio(form);
            if (respp) {
                this.props.sampleObj({recording: [respp]})
            } else {
                this.props.sampleObj({recording: []})
            }
        } else {
            this.props.sampleObj({recording: []})
        }


    }


    render() {

        const {info = {}} = this.props;
        const {recordingNow} = this.state;


        let {preparationDuration = 2000, recordingDuration = 10000} = info;

        return (<div className={'row'}>
                <div className={'row col-md-12 mt-3 mb-3 '}>
                    <div className={'col-md-3'}>

                    </div>
                    <div className={'col-md-6 text-right'}>
                        <div className={'mb-3'}>
                            {/*<AudioPlayer fileUrl={`${info.audiourl}`}/>*/}
                            <AudioPlayer ref={this.audioPlayerRef} fileUrl={info.audiourl}
                                         onAudioComplete={this.audioCompleted}/>
                        </div>
                        <div className={'mb-3 row'}>
                            <div className={'col-md-12'}>

                                {
                                    recordingNow ? <AudioRecorder
                                        submitRecording={(obj) => this.saveShortAnswer(obj)}
                                        ref={this.audioRef}
                                        preparationDuration={preparationDuration}
                                        recordingDuration={recordingDuration}/> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-3'}>

                    </div>

                </div>


            </div>
        )
    }
}

export default AnswerShortQuestion
