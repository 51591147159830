import React, {Component} from 'react';
import renderHTML from 'react-render-html';

import AudioRecorder from './audioRecorder';
import Request from '../../request';

class ReadAloud extends React.Component {

    state = {};

    constructor(props) {
        super(props)

        this.audioRef = React.createRef();
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }


    getQuestionAnswer = () => {
        this.audioRef.current.stopRecording()
    }

    saveReadAloud = async (obj) => {

        this.props.moveNext();

        if (obj) {

            let form = new FormData();

            form.append('file', obj ? obj.blob : null);
            let respp = await Request.uploadAudio(form);

            if (respp) {
                this.props.sampleObj({recording: [respp]})
            } else {
                this.props.sampleObj({recording: []})
            }
        } else {
            this.props.sampleObj({recording: []})
        }


    }


    render() {

        const {info} = this.props;
        let {preparationDuration = 30000, recordingDuration = 30000} = info;


        return (<div className={'row'}>
                <div className={'col-md-12 mt-3 mb-3 col-lg-12 col-xl-12'}>
                    <div className={'row'}>
                        <div className={'col-md-4 mt-3 mb-3 col-lg-4 col-xl-4'}></div>
                        <div className={'col-md-4 mt-3 mb-3 col-lg-4 col-xl-4'}>
                            <AudioRecorder
                                submitRecording={(obj) => this.saveReadAloud(obj)}
                                ref={this.audioRef}
                                preparationDuration={preparationDuration}
                                recordingDuration={recordingDuration}/>
                        </div>
                        <div className={'col-md-4 mt-3 mb-3 col-lg-4 col-xl-4'}></div>

                    </div>
                </div>
                <div className="row col-md-12">
                    <div className="col-md-12 col-lg-12 col-xl-12 wrtn-que">

                        {
                            info.body ? renderHTML(info.body) : ''
                        }

                    </div>
                </div>

            </div>
        )
    }


}


export default ReadAloud
