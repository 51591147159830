import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import QuestionDescription from './questionDescription'
import PreparationTimer from './preparationTimer'
import ReactAudioPlayer from 'react-audio-player';
import AudioRecorder from "./audioRecorder";
import Request from "../../request";

class DescribeImage extends React.Component {

    state = {
        audioDuration: 0
    };

    constructor(props) {
        super(props)
        this.audioRef = React.createRef()
    }

    componentDidMount() {


    }

    getQuestionAnswer = () => {

        if (this.audioRef && this.audioRef.current) {
            this.audioRef.current.stopRecording()
        } else {
            this.props.moveNext()
            this.props.sampleObj({recording: []})
        }

    }
    saveDescribeImage = async (obj) => {

        this.props.moveNext()
        if (obj) {
            let form = new FormData();
            form.append('file', obj ? obj.blob : null);
            let respp = await Request.uploadAudio(form);
            if (respp) {
                this.props.sampleObj({recording: [respp]})
            } else {
                this.props.sampleObj({recording: []})
            }
        } else {
            this.props.sampleObj({recording: []})
        }

    }


    render() {

        const {info} = this.props;

        let {preparationDuration = 25000, recordingDuration = 40000} = info;


        const imageUrl = info && info.questionImage && info.questionImage.length && info.questionImage[0].url ? info.questionImage[0].url : '/sampleImage/describeImage.png'
        return (<div className={'row'}>
                <div className="row col-md-12 mb-3 mt-3">
                    <div className="col-md-6 col-lg-6 col-xl-6 ">

                        <img className={'describeimage'} src={imageUrl}/>
                    </div>
                    <div className={'col-md-6 mt-3 mb-3 col-lg-6 col-xl-6'}>
                        <AudioRecorder
                            submitRecording={(obj) => this.saveDescribeImage(obj)}
                            ref={this.audioRef}
                            preparationDuration={preparationDuration}
                            recordingDuration={recordingDuration}/>
                    </div>
                </div>


            </div>
        )
    }
}

export default DescribeImage
