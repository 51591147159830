import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Request from './../../request'
import moment from "moment";
import lodash from "lodash";
import styles from "./styles.css"
import {NotificationManager} from "react-notifications";

class Dashboard extends React.Component {
    startExerciseTest = async (item, key) => {
        let cloneData = lodash.clone(this.state.inHouseTestArr);
        cloneData[key].isLoading1 = true;
        this.setState({inHouseTestArr: cloneData})
        let CheckPermission = localStorage.getItem('audio')
        if (CheckPermission) {
            let obj = {
                "testIds": [item._id],
                totalAmount: 0
            }
            let resp = await Request.startExerciseTest(obj);
            if (resp.success && resp.data && resp.data._id) {
                this.getSingleTestId(resp.data._id, async (resp1) => {
                    let resp111 = await Request.startTest({studentTestId: resp.data._id});
                    /*
                    localStorage.setItem('attemptedQuestion', JSON.stringify(resp.data.questions))
                     */

                    if (resp111.success) {
                        if (resp111.newToken) {
                            localStorage.setItem('token', resp111.newToken);
                        }
                        window.location.href = '/viewQuestion/' + resp.data._id
                    } else {
                        alert("Sorry cannot start test")
                        return;
                    }
                });
            } else {
                alert("Sorry cannot start test")
                return;
            }
        } else {
            alert("Permission Denied!! Change your browser settings to use microphone")
            return;
        }


    }

    constructor() {
        super();
        this.state = {
            inHouseTestArr: [],
            testHistory: [],
            testArr: [],
            totalAmount: 0,
            user: null
        }
    }

    componentDidMount() {
        this.getInHouseTest();
        let localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        this.setState({user: localUser})
    }

    async getInHouseTest() {
        let resp = await Request.getInHouseTest();
        this.setState({inHouseTestArr: resp.testArr})
    }

    getSingleTestId(testId, callback) {
        Request.getSingleTest({id: testId}).then(
            ({data, error}) => {
                if (!error) {
                    localStorage.setItem('testData', JSON.stringify(data))
                    return callback('done')
                } else {
                    return callback('done')
                }
            }
        )
    }


    render() {
        const {testArr, resultArr, testHistory, user, inHouseTestArr} = this.state;
        return (
            <div className="content content-white">

                <div className="row mb-4">
                    <div className="col-xl-12 col-sm-12">
                        <h3 className="text-dark hed">Practise Test</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-md-12 prevexm prevexm1">
                        <div className="card card-default">
                            {inHouseTestArr && inHouseTestArr.map((item, key) => {
                                return (
                                    <div className="row mt-2 exm-hgt exm-hgt-new" key={key}>
                                        <div className="col-xl-6 col-md-12" style={{padding: 0}}>
                                            <div className="card card-default qusns" data-scroll-height="675">
                                                <h4>{item.testName}</h4>
                                                <p>There are 3 sections
                                                    {item && item.questions ?
                                                        <span> ( {item.questions.length} Questions )</span> : null
                                                    } .

                                                </p>

                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-12" style={{padding: 0}}>
                                            <div className="card card-default" data-scroll-height="675">
                                                <div className="recrd">
                                                    <button type="button"
                                                            onClick={() => (this.startExerciseTest(item, key))}
                                                            disabled={item.isLoading1}
                                                            className="mb-1 btn btn-success">
                                                        {
                                                            item.isLoading1 ? 'PLEASE WAIT...' :
                                                                ' START TEST NOW'
                                                        }

                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>


            </div>
        )
    }
}


export default Dashboard;


