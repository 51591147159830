import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import moment from 'moment'
import Request from './../../request'
import {NotificationManager} from 'react-notifications';
import Countries from './../../jsons/countries'
import CountryCode from './../../jsons/countryCode'
import States from './../../jsons/states'

class UserProfile extends React.Component {
    handleSubmit = async (event) => {
        event.preventDefault();
        let obj = {
            _id: this.state._id,
            mobile: this.state.mobile,
            country: this.state.country,
            state: this.state.stateName,
            countryCode: this.state.countryCode,
            city: this.state.city,
            postcode: this.state.postcode,
            address: this.state.address,
            gender: this.state.gender,
            dob: this.state.dob,
            name: this.state.name
        }
        let respObj = await Request.updateUserProfile(obj);
        if (respObj.success) {
            NotificationManager.success(respObj.message);
        } else {
            NotificationManager.error(respObj.message);
        }
    }

    constructor() {
        super();
        this.state = {
            userData: null,
            name: '',
            email: '',
            dob: '',
            country: '',
            countryCode: '',
            gender: 'Male',
            stateArr: [],
            stateName: '',
            address: '',
            city: '',
        }
    }

    async getUserData() {
        let respData = await Request.getSingleUserDetails(this.state.userData);
        if (respData.success) {
            let obj = respData.data;
            obj.userData = this.state.userData;
            obj.stateName = obj.state ? obj.state : '';
            if (obj.dob) {
                obj.dob = moment(obj.dob).format('YYYY-MM-DD');
                console.log(obj.dob);
            }
            this.setState(obj);
            this.chooseCountry(obj.country);
        }
    }

    componentDidMount() {
        this.setState({
            userData: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        })
        setTimeout(() => {
            this.getUserData();
        }, 1000)
    }

    setCountryCode() {

    }

    chooseCountry(data) {
        let findCountryObj = lodash.find(Countries, (item) => {
            return item.name == data
        })
        let stateArr = []
        if (findCountryObj) {
            let stateArr = lodash.filter(States, (item) => {
                return item.country_id == findCountryObj.id
            })
            this.setState({
                stateArr: stateArr,
                country: data
            })
        } else {
            this.setState({
                stateArr: [],
                country: data
            })
        }
    }

    render() {
        const {stateArr} = this.state;
        return (
            <div className="container mt-1 pt-2">
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className="card card-default">
                            <div className="card-header card-header-border-bottom card-header-new">
                                <h2>User Profile</h2>
                            </div>
                            <div className="card-body card-body1">
                                <form onSubmit={this.handleSubmit}>
                                    <div className={'row'}>
                                        <div className="col-md-6">
                                            <label>Name</label>
                                            <input type="text" className="form-control input-lg" id="name"
                                                   aria-describedby="nameHelp" value={this.state.name}
                                                   onChange={(e) => this.setState({name: e.target.value})}
                                                   required
                                                   placeholder="Name"/>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label>Country Code</label>
                                                    <select
                                                        className="form-control  form-control-select input-xs"
                                                        value={this.state.countryCode} required
                                                        onChange={(e) => this.setState({countryCode: e.target.value})}>
                                                        <option value={''}>Select Country Code</option>

                                                        {CountryCode.map((item, index) => (
                                                            <option key={index} value={item.dial_code}>
                                                                {item.code} ({item.dial_code})
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className='col-md-7'>
                                                    <label>Mobile No</label>
                                                    <input type="text" className="form-control input-lg"
                                                           id="mobile"
                                                           value={this.state.mobile}
                                                           required
                                                           onChange={(e) => this.setState({mobile: e.target.value})}
                                                           placeholder="Mobile No"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row mt-3'}>
                                        <div className="col-md-6">
                                            <label>Date of Birth</label>
                                            <input type="date" className="form-control input-lg" id="dob"
                                                   value={this.state.dob}
                                                   onChange={(e) => this.setState({dob: e.target.value})}
                                                   required
                                                   placeholder="Date of Birth"/>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Gender</label>
                                            <div className="mt-2" style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <label className="control outlined control-radio radio-success">Male
                                                    <input type="radio" name='gender' value={this.state.gender}
                                                           checked={this.state.gender == 'Male'}
                                                           required
                                                           onChange={(e) => this.setState({gender: 'Male'})}/>
                                                    <div className="control-indicator"></div>
                                                </label>
                                                <label className="control outlined control-radio radio-success">Female
                                                    <input type="radio" name='gender' value={this.state.gender}
                                                           checked={this.state.gender == 'Female'}
                                                           required
                                                           onChange={(e) => this.setState({gender: 'Female'})}/>
                                                    <div className="control-indicator"></div>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={'row mt-3'}>
                                        <div className="col-md-6 ">
                                            <label>Country</label>
                                            <select className="form-control form-control-select input-xs"
                                                    value={this.state.country}
                                                    required
                                                    onChange={(e) => this.chooseCountry(e.target.value)}>
                                                <option value=''>Select Country</option>
                                                {Countries.map((item, index) => (
                                                    <option key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}


                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>State</label>
                                            <select className="form-control form-control-select input-xs"
                                                    value={this.state.stateName}
                                                    required
                                                    onChange={(e) => this.setState({stateName: e.target.value})}>
                                                {stateArr.map((item, index) => (
                                                    <option key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}


                                            </select>
                                        </div>
                                    </div>
                                    <div className={'row mt-3'}>
                                        <div className="col-md-6 ">
                                            <label>City</label>
                                            <input type="text" className="form-control input-lg"
                                                   id="city"
                                                   value={this.state.city}
                                                   required
                                                   onChange={(e) => this.setState({city: e.target.value})}
                                                   placeholder="City"/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Postal Code</label>
                                            <input type="text" className="form-control input-lg"
                                                   id="postcode"
                                                   value={this.state.postcode}
                                                   required
                                                   onChange={(e) => this.setState({postcode: e.target.value})}
                                                   placeholder="Post Code"/>
                                        </div>
                                    </div>

                                    <div className={'row mt-3'}>
                                        <div className="col-md-6 ">
                                            <label>Address</label>
                                            <textarea type="text" className="form-control input-lg"
                                                      id="adress"
                                                      value={this.state.address}
                                                      required
                                                      onChange={(e) => this.setState({address: e.target.value})}
                                                      placeholder="Street Address"></textarea>
                                        </div>

                                    </div>

                                    <div className={'row'}>
                                        <div className="col-md-12 mt-3 recrd align-left">
                                            <button className="btn btn-primary" type="submit">Update Profile</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default UserProfile;
