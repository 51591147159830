import axios from 'axios'

import {apiUrl} from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})


let getToken = () => {
    return ({'headers': {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
    //return ({'headers': {'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDExY2JhYWM3NzJiMjM1YjcxZDY4NWEiLCJlbWFpbCI6Im5hdmVlbkBzY2l6ZXJzLmNvbSIsInVzZXJUeXBlIjoiQWRtaW4iLCJuYW1lIjoiTmF2ZWVuIiwibW9iaWxlIjoiOTAzNDU3NTg0NCIsImlhdCI6MTU2MTQ0NzQzOCwiZXhwIjoxNTYxNTMzODM4fQ.IayvXxpAfSPwF_YOof1OLj1aA4xBr-YSTmFsl_rKyeM'}})
}

class Request {

    constructor() {

        console.log("construcotr")
    }

    error = (err) => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
                //window.location.reload()
            }
        } catch (e) {
        }
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/login', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllUser(data) {
        return new Promise((next) => {
            authAxios
                .get('/users', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addUser(data) {
        return new Promise((next) => {
            authAxios
                .post('/users', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    //region Questions
    viewQuestion(id) {
        return new Promise((next) => {
            authAxios
                .get(`/question/${id}`)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    //endregion

    //region test
    startTest(data) {
        return new Promise((next) => {

            authAxios
                .post('/save/startTest', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    saveAnswer(data) {
        return new Promise((next) => {

            authAxios
                .post('/test/saveAnswer', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    moveNext(data) {
        return new Promise((next) => {


            authAxios
                .post('/test/moveNext/', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    saveElapsedTime(data) {
        return new Promise((next) => {

            authAxios
                .post('/test/saveElapsedTime', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    //endregion


    signup(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/signup', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    checkEmailIsUnique(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/checkEmailIsUnique', {email: data})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getPublishTestForUser() {
        return new Promise((next, error) => {
            authAxios
                .get('/getPubishedExamsForUser', getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    purchaseTest(data) {
        return new Promise((next, error) => {
            authAxios.post('/student/purchaseTest', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    checkout(data) {
        return new Promise((next, error) => {
            authAxios.post('/checkout', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    studentTransactions(data) {
        return new Promise((next, error) => {
            authAxios.post('/viewAllTransactions', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    getStudenTodayTest() {
        return new Promise((next, error) => {
            authAxios
                .get('/student/today/exams', getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    forgotPasswordAjax(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/forgotPassword', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    studentTestHistory(data) {
        return new Promise((next, error) => {
            authAxios.post('/student/testHistory', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    changePasswordFxn(data) {
        return new Promise((next, error) => {
            authAxios.post('/changePassword', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    resetPassword(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/resetPassword', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getSingleUserDetails(data) {
        return new Promise((next, error) => {
            authAxios
                .get(`/user/${data._id}`, data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    updateUserProfile(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/updateUser', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    logout() {
        return new Promise((next, error) => {
            authAxios
                .post('/logout', {}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    uploadAudio(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/answerfilesUploader', data, getToken())
                .then((d) => {

                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    listLocations() {
        return new Promise((next, error) => {
            next('done')

        })
    }


    getSingleTest(data) {
        return new Promise(next => {
            authAxios
                .get('/singleTest/' + data.id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    singleTestAllQuestion(data) {
        return new Promise(next => {
            authAxios
                .get('/singleTestAllQuestion/' + data.id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getStudentAttemptedQuestions(data) {
        return new Promise(next => {
            authAxios
                .get('/getStudentAttemptedQuestions/' + data.id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    setBreakTimer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/setBreakTimer', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    updateBreakTimer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/updateBreakTimer', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    updateBreakTimerStatus(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/updateBreakTimerStatus', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getBreakTimer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/getBreakTimer', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    removeBreakTimer(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/removeBreakTimer', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getInHouseTest() {
        return new Promise((next, error) => {
            authAxios
                .get('/inHouseTest', getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    startExerciseTest(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/student/exerciseTest', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    downloadResultPdf(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/student/downloadResult', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    updatePaymentAndCreateTest(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/payment/update_create_test', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }
}

export default new Request()
