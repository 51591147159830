import React from 'react';
import {Route} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/dist/react-notifications.css'

const LoginLayout = ({children}) => (
    <div>
        {children}
    </div>
);

const LoginLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <LoginLayout>
                <Component {...matchProps} />
                <NotificationContainer/>
            </LoginLayout>
        )}/>
    )
};

export default LoginLayoutRoute;
