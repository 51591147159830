import React, {Component} from 'react'
import Beep from './beep';

let player = null;

class AudioPlayerComp extends React.Component {
    _isMounted = false;

    state = {
        audioDuration: 0,
        currentTime: 0,
        maxTime: null,
        status: 'Playing'
    };

    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }

    componentDidMount() {
        this._isMounted = true;

        player = document.getElementById('myplayer');
        player.onloadedmetadata = () => {
            if (this._isMounted) {
                player.play();
                this.setState({maxTime: parseInt(player.duration)});
            }

        };

    }

    componentWillUnmount() {
        this._isMounted = false;

    }

    pauseAudio = () => {

        if (player) {

            player.pause();
        }

    }


    updateBar = () => {
        if (player.currentTime == player.duration) {
            if (this._isMounted) {
                this.setState({currentTime: parseInt(player.currentTime), status: 'Completed'})
                this.props.onAudioComplete()
            }

        } else {
            if (this._isMounted) {
                this.setState({currentTime: parseInt(player.currentTime)})
            }
        }

    }


    render() {

        const {info, fileUrl = '/audio/repeatSentence.mp3'} = this.props;
        const {currentTime, maxTime, status} = this.state;


        return (<div className={'row text-right'}>


                <Beep ref={this.ref}/>

                <div className={'col-md-12'}>
                    {/* <audio controls id="myplayer" preload="metadata">
                        <source src="autoharp/tone0.ogg" type="audio/ogg">
                    </audio>*/}

                    <audio id="myplayer" preload="metadata" onTimeUpdate={this.updateBar}
                           src={fileUrl}
                           ref={(element) => {
                               this.rap = element;
                           }}>
                    </audio>


                    {
                        maxTime ? <div className="card mb-3 p-4 rounded border curnt-st">
                            <div className="card-block">
                                <p className=" secnd pb-3">{status}</p>
                                <progress id="seek-obj" className={'audioprogress'} value={currentTime}
                                          max={maxTime}></progress>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>{currentTime}</div>
                                    <div className={'col-md-6'}>{maxTime}</div>
                                </div>
                            </div>
                        </div> : ''
                    }

                </div>

            </div>


        )
    }
}

export default AudioPlayerComp
