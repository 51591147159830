import React, {Component} from 'react';
import Countdown from "react-countdown-now";
import styles from "../../index.css";
import _ from "lodash";
import moment from "moment";
import Request from "../../request";

let myVar = ''
let myVar2 = ''

class BreakTime extends React.Component {

    state = {
        info: 0,
        currentTask: 0,
        currentQuestion: 0,
        questionId: null,
        testId: '5d22e4574dceb2261a6d771c',
        totalQuestion: 0,
        // breakTimerValue: 1000 * 60 * 10,
        breakTimerValue: 0,
        startTimer: null,
        endTimer: null
    }
    updateBreakTimer = async () => {
        let resp = await Request.updateBreakTimer({_id: this.state.testId});
        if (resp.success) {

        }
    }
    updateTimer = () => {
        let breakTimeStorage = this.state;
        let obj = {
            breakTimerValue: breakTimeStorage.endTimer - Date.now(),
        }
        this.setState(obj);
        if (obj.breakTimerValue <= 0) {
            clearInterval(myVar);
            clearInterval(myVar2);
            this.props.timerClose()
        }
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        let options = [];


    }

    componentDidMount() {
        /*const {match: {params}} = this.props;

        const {testId} = params;*/
        let {info, breakTimer} = this.props;
        this.setState({
            testId: info._id,
            endTimer: breakTimer.endTimer,
            breakTimerValue: breakTimer.breakTimerValue,
            currentTimer: breakTimer.currentTimer
        }, () => {
            myVar = setInterval(() => this.updateTimer(), 1000);
            myVar2 = setInterval(() => this.updateBreakTimer(), 5000);
        })
    }

    render() {
        const {breakTimerValue} = this.state;

        return (
            <div className="content-wrapper">
                <div className="content breakTimer">
                    <img src={'../assets/img/breakTime.png'} style={{height: 200}}/>
                    <br/>

                    {
                        breakTimerValue ?
                            <Countdown
                                date={Date.now() + breakTimerValue}
                                onComplete={this.moveNext}
                                renderer={({hours, minutes, seconds, completed}: CountdownRenderProps) => {
                                    return (
                                        <span>
                                                        {hours ? <strong>{hours}
                                                            <small> hour</small>
                                                            : </strong> : null}
                                            {minutes ? <strong>{minutes}
                                                <small> min</small>
                                                : </strong> : null}
                                            <strong>{seconds}
                                                <small> seconds</small></strong>
                                                    </span>

                                    );
                                }}/> : null
                    }


                </div>

            </div>
        )
    }


}

export default BreakTime
