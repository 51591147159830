import React, {Component} from 'react';


import AudioPlayer from "./audioPlayer";

class Wfd_20 extends React.Component {

    state = {val: null, totalWords: 0};

    constructor(props) {
        super(props)
        this.audioPlayerRef = React.createRef()

    }

    getQuestionAnswer = () => {
        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }
        this.props.moveNext()
        let {val} = this.state;
        this.props.sampleObj({text: val ? val : null})
    }

    setTextValue = (val) => {
        let totalWords = 0
        let wordsArr = []
        if (val) {
            wordsArr = val.split(' ');
        }

        this.setState({val: val, totalWords: wordsArr.length})
    }


    render() {

        const {info} = this.props;
        const {totalWords} = this.state;
        info.answerDuration = info.answerDuration * 1000;

        return (

            <div className={'row'}>

                <div className={'row col-md-12'}>
                    <div className={'row col-md-4'}></div>
                    <div className={'row col-md-4 mt-3'}>
                        <AudioPlayer ref={this.audioPlayerRef} onAudioComplete={() => {

                        }} fileUrl={info.audiourl}/>
                    </div>
                    <div className={'row col-md-4'}></div>
                </div>
                <div className="row col-md-12 mt-4">
                    <div className={'col-md-12 text-right'}>
                        <span>Total Words: {totalWords}</span>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <textarea rows="4" style={{width: '100%', padding: 10}}
                                  onChange={(e) => this.setTextValue(e.target.value)}
                                  spellCheck={false}></textarea>
                    </div>
                </div>


            </div>
        )
    }


}


export default Wfd_20
