import React, {Component} from 'react';

import AudioPlayer from "./audioPlayer";
import {API_URL} from "../../request";
import _ from 'lodash';

class HighlightCorrectSummary_16 extends React.Component {

    state = {val: null, options: []};

    constructor(props) {
        super(props)
        this.audioPlayerRef = React.createRef()

    }

    componentDidMount() {


        let options = []
        _.each(this.props.info.mcqOptions, (val) => {
            val.checked = false
            options.push(val)
        })
        this.setState({options})
    }

    getQuestionAnswer = () => {

        if (this.audioPlayerRef && this.audioPlayerRef.current) {
            this.audioPlayerRef.current.pauseAudio()
        }

        this.props.moveNext()
        let {options} = this.state;

        options = _.filter(options, x => x.checked == true)

        if (options && options.length) {
            this.props.sampleObj({
                options: [{
                    value: options[0].value,
                    optionId: options[0]._id
                }]
            })
        } else {
            this.props.sampleObj({
                options: []
            })
        }

    }

    checkOption = (val) => {
        let {options} = this.state;
        _.each(options, (vv) => {
            if (vv._id == val._id) {
                vv.checked = true;
            } else {
                vv.checked = false;
            }
        })


    }


    render() {

        const {info} = this.props;
        const {options} = this.state;


        return (<div className={'row'}>

                <div className="mt-3 col-md-7 col-lg-7 col-xl-7 wrtn-que">
                    <div className={'mb-3'}>
                        <AudioPlayer ref={this.audioPlayerRef} fileUrl={info.audiourl} onAudioComplete={() => {

                        }}/>
                    </div>
                </div>
                <div className="mt-3 col-md-5 col-lg-5 col-xl-5 wrtn-que">
                    <p className={'mcqhead'}>
                        Listen audio carefully and mark correct summary
                    </p>
                    <div>
                        {options.map((val, index) => {
                            return (
                                <label key={index} className="control control-radio">{val.value}
                                    <input type="radio" name="MultipleChoiceChooseMultipleAnswerOptions_9" value={val}
                                           onChange={(e) => this.checkOption(val)}/>
                                    <div className="control-indicator"></div>
                                </label>


                            )
                        })}
                    </div>
                </div>

            </div>
        )
    }


}

export default HighlightCorrectSummary_16
