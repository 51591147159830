export default {}
export const pageTitle = 'Black Tempalte'
export const subTitle = 'Powered by we'
//export const apiUrl = `https://summitpteapi.scizers.com`;
export const apiUrl = `https://pteapi.scizers.com`;

export const taskDetails = {
    '1': {
        name: 'Read Aloud',
        description: 'Look at the text below. In 30 seconds you must read this text as naturally and clearly as possible. You have 30 seconds to read.'
    },
    '2': {
        name: 'Repeat Sentence',
        description: 'You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear sentence only once.'
    },
    '3': {
        name: 'Describe Image',
        description: 'Look at the diagram below. In 25 seconds, please speak into the microphone and describe in detail what the image is showing. You will have 40 seconds to give your response.'
    },
    '4': {
        name: 'Re-tell Lecture',
        description: 'you will hear a lecture. After listening to the lecture, in 10 seconds, please speak into he microphone and retell what you have just heard from the lecture' +
            'in your words. You will have 40 seconds to give your response.'
    },
    '5': {
        name: 'Answer Short Question',
        description: 'you will hear a lecture. After listening to the lecture, give your answer in a word or two'
    },
    '6': {
        name: 'Summarize Written Text',
        description: 'Read the passage below and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. '
    },
    '7': {
        name: 'Essay',
        description: 'You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.\n'
    },
    '8': {
        name: 'R & W Fill in the blanks',
        description: 'Below is a text with blanks. Click on each blank, a list of choice will appear. Select the appropriate answer choice for each blank.'
    },
    '9': {
        name: 'Choose Single Answers',
        description: 'Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.'
    },
    '10': {
        name: 'Choose Multiple Answers',
        description: 'Read the text and answer the question by selecting all the correct responses. More than one response is correct.'
    },
    '11': {
        name: 'Re-order Paragraphs',
        description: 'The text box in the left panel have been placed in a random order. Restore the original order by dragging  the text boxes from left panel to right panel'
    },
    '12': {
        name: 'Fill in the blanks',
        description: 'In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.'
    },

    '13': {
        name: 'Summarize Spoken Text',
        description: 'You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the Quality of Your writing and on how well your response presents the key points presented in the lecture'
    },
    '14': {
        name: 'Choose multiple answers',
        description: 'Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.'
    },
    '15': {name: 'Fill in the blanks', description: 'You will hear a recording. Type the missing words in each blank.'},
    '16': {
        name: 'Highlight correct summary',
        description: 'You will hear an audio. Click on the paragraph that best relates to the recoding.'
    },
    '17': {
        name: 'Choose single answers',
        description: 'Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct'
    },
    '18': {
        name: 'Select Missing Word',
        description: 'You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.'
    },
    '19': {
        name: 'Highlight Incorrect Words',
        description: 'You will hear a recording about an analysis of medical research findings. At the end of the recording the last word or group of words has been replaced by a beep. Select the correct option to complete the recording.'
    },
    '20': {
        name: 'Write From Dictation',
        description: 'You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.'
    },


}


