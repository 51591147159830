import React, {Component} from 'react';

import AudioPlayer from "./audioPlayer";
import {API_URL} from "../../request";
import _ from 'lodash';

class FillInTheBlanks_12 extends React.Component {

    state = {val: null, queOptions: [], reorderOptions: []};

    constructor(props) {
        super(props)
    }

    componentDidMount() {

        let arr = [];
        _.each(this.props.info.reorderParagraph, (val) => {
            arr.push({
                _id: val._id,
                value: null,
                actualOrderNo: val.orderNo,
                answerOrderNo: null,
                optionId: null
            })
        })
        this.setState({reorderOptions: arr, queOptions: this.props.info.reorderParagraph})

    }

    onDragStart = (ev, v) => {
        ev.dataTransfer.setData('id', JSON.stringify(v))

    }
    onQueDrop = (ev, index) => {

        let obj = ev.dataTransfer.getData('id');


        let rejectNow = false;

        if (obj) {
            obj = JSON.parse(obj)

            let {reorderOptions, queOptions} = this.state;
            _.each(reorderOptions, (val, ind) => {
                if (ind == index && !val.value) {
                    val.value = obj.value
                    val.answerOrderNo = index
                    val.optionId = obj._id
                    rejectNow = true
                }
            })

            if (rejectNow) {
                queOptions = _.reject(queOptions, (val) => val.value == obj.value)
            }


            this.setState({reorderOptions, queOptions})

        }

    }


    onDragAnswerStart = (ev, v) => {

        ev.dataTransfer.setData('aid', JSON.stringify(v))

    }


    onAnsDrop = (ev) => {

        let obj = ev.dataTransfer.getData('aid');


        if (obj) {

            obj = JSON.parse(obj)

            let {reorderOptions, queOptions} = this.state;
            queOptions.push({_id: obj.optionId, value: obj.value, orderNo: obj.actualOrderNo})
            reorderOptions = _.each(reorderOptions, (val) => {
                if (val.value == obj.value) {
                    val.value = null;
                    val.answerOrderNo = null;
                    val.optionId = null;
                }
            })
            this.setState({reorderOptions, queOptions})

        }
    }


    getQuestionAnswer = () => {
        this.props.moveNext()
        let {reorderOptions} = this.state;
        reorderOptions = reorderOptions.filter((val) => {
            return (val.optionId && val.value)
        })
        this.props.sampleObj({reorderOptions})
    }


    render() {

        const {info} = this.props;
        const {reorderOptions, queOptions} = this.state;

        return (<div className={'row'}>

                <div className="mt-3 col-md-12 col-lg-12 col-xl-12 wrtn-que">
                    {info.textArray.map((val, index) => {
                        return (
                            <span className={'fibb'} key={index}>
                                {val}
                                {reorderOptions[index] && info.textArray.length - 1 > index ?
                                    <span onDragOver={(e) => {
                                        e.preventDefault()
                                    }} onDrop={(e) => this.onQueDrop(e, index)}
                                          draggable
                                          onDragStart={(e) => this.onDragAnswerStart(e, reorderOptions[index])}
                                          className={'dragArea'}>{reorderOptions[index].value}</span> : null}

                            </span>
                        )
                    })}
                </div>
                <div className={'separartoe row col-md-12 mb-5 mt-5'} onDragOver={(e) => {
                    e.preventDefault()
                }}
                     onDrop={(e) => this.onAnsDrop(e)}>
                    <ul>
                        {queOptions.map((val, index) => {

                            return (<li draggable
                                        onDragStart={(e) => this.onDragStart(e, val)}
                                        className={'draglist'}
                                        key={index}>{val.value}</li>)

                        })

                        }
                    </ul>
                </div>


            </div>
        )
    }


}

export default FillInTheBlanks_12
